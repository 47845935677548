export const ADMIN_LOGGED_IN = 'ADMIN_LOGGED_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SHOW_SUCCESS_ALERT = 'SHOW_SUCCESS_ALERT';
export const SHOW_ERROR_ALERT = 'SHOW_ERROR_ALERT';
export const SHOW_DIALOG_BOX = 'SHOW_DIALOG_BOX';
export const SAVE_MATCH_DETAILS = 'SAVE_MATCH_DETAILS';
export const CLEAR_MATCH_DETAIS = 'CLEAR_MATCH_DETAIS';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_BACKDROP = "SHOW_BACKDROP";
export const HIDE_BACKDROP = "HIDE_BACKDROP";
export const SHOW_UPLOAD_LOADER = "SHOW_UPLOAD_LOADER";
export const HIDE_UPLOAD_LOADER = "HIDE_UPLOAD_LOADER";
export const UPDATE_EMPLOYER_LIST_CONTENT_DATA = "UPDATE_EMPLOYER_LIST_CONTENT_DATA";
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const EMPLOYER_LIST_CONTENT_DATA = "EMPLOYER_LIST_CONTENT_DATA";
export const UPDATE_EMPLOYER_BLOCK_STATUS = "UPDATE_EMPLOYER_BLOCK_STATUS";
export const DELETE_USER_ROW = "DELETE_USER_ROW";
export const SKILL_LIST_CONTENT_DATA = "EMPLOYER_LIST_CONTENT_DATA";
export const SKILL_STATUS_UPDATE = "SKILL_STATUS_UPDATE";
export const WORKER_LIST_CONTENT_DATA = "WORKER_LIST_CONTENT_DATA";
export const UPDATE_WORKER_BLOCK_STATUS = "UPDATE_WORKER_BLOCK_STATUS";

export const SPAM_TEXT_LIST_CONTENT_DATA = "SPAM_TEXT_LIST_CONTENT_DATA";
export const SPAM_TEXT_STATUS_UPDATE = "SPAM_TEXT_STATUS_UPDATE";

