// react imports
import {combineReducers} from 'redux'

// local imports
// import { countryListReducer } from './country';
import { userReducer } from './user';
import { alertsReducer } from './alerts';
import { dashboardReducer } from './dashboard';
import { categoryListReducer } from './employers';
import { skillListReducer } from './skills';
import { workerListReducer } from './workers';
// import { employerListReducer } from './employerList';
import { spamTextListReducer } from './spamText';


// write your functions here
const rootReducer = combineReducers({
    // countryList: countryListReducer,
    user: userReducer,
    alerts: alertsReducer,
    dashboard: dashboardReducer,
    employers: categoryListReducer,
    skills: skillListReducer,
    workers: workerListReducer,
    // employers: employerListReducer
    spamText: spamTextListReducer
})

export default rootReducer;

