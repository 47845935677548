import { useContext, useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
// @ts-ignore
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';

import {
  Box,
  Drawer,
  styled,
  Divider,
  useTheme,
  Button,
  darken,
  Switch
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import { useDispatch, useSelector } from 'react-redux';
import { userLoggedOut } from 'src/store/user';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from 'src/store/alerts';
import { getRequest, isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { TRIAL_SUBSCRIPTION_STATUS } from 'src/constants';
import { RootState } from 'src/store';
import SubjectIcon from '@mui/icons-material/Subject';


const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);


// const classes = useStyles();
function TrialSubscriptionStatus() {

  const [subscriptionChecked, setSubscriptionChecked] = useState(false);

  const dispatch = useDispatch();

  let token = useSelector(
    (state: RootState) => state.user.authorization.access_token
  );

  useEffect(() => {
    getTrialSubscriptionStatus();
  }, []);

  const getTrialSubscriptionStatus = async () => {
    console.log("setting trial subscription status >>")
    let res: any = {}
    res = await getRequest(
      TRIAL_SUBSCRIPTION_STATUS,
      token
    );

    if (res) {
      if (isApiCodeSucess(res)) {
        let trial_sub_status = res.data?.data.subscription_status;
        let response_msg = res.data?.message;
        setSubscriptionChecked(trial_sub_status);
      }
    }
  }

  const handleTrialSubscription = async (event) => {
    // setSubscriptionChecked(event.target.checked)
    dispatch(ShowProgressBar());

    const res: any = await postRequest(
      TRIAL_SUBSCRIPTION_STATUS,
      {},
      token
    );

    dispatch(HideProgressBar());
    if (res) {
      if (isApiCodeSucess(res)) {
        let trial_sub_status = res.data?.data.subscription_status;
        let response_msg = res.data?.message;
        setSubscriptionChecked(trial_sub_status);
        if (trial_sub_status) {
          dispatch(ShowSuccessAlert({ visible: true, message: response_msg }));
        }
        else {
          dispatch(ShowErrorAlert({ visible: true, message: response_msg }));
        }
      }
    }
  }

  return (
    <Switch
      onChange={handleTrialSubscription}
      checked={subscriptionChecked}
      size="small"
      color="secondary"
      sx={{
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "green !important",
          backgroundColor: 'rgb(187 219 178 / 16%)'
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: 'green !important'
        },
        "&:hover .MuiSwitch-switchBase": {
          color: 'brown'
        },
        "& .MuiSwitch-switchBase.Mui-disabled": {
          color: 'green',
          backgroundColor: 'rgb(187 219 178 / 16%)'
        },
        "& .MuiSwitch-track.Mui-checked": {
          backgroundColor: 'green'
        }
      }}
    />
  )
}

function Sidebar(props) {
  let { isDesktop } = props
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  const dispatch = useDispatch();
  const handleStore = () => {
    dispatch(userLoggedOut())
  }




  return (
    <>
      <SidebarWrapper
        className='custom-sidebar-color'
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          // background: '#aed3c21a',
          // background: 'radial-gradient(#254630ad, #dcc1c1a1)',
          // background:
          //   theme.palette.mode === 'dark'
          //     ? alpha(lighten(theme.header.background, 0.1), 0.5)
          //     : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 52
              }}
            >
              <Logo />
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        <Box mt={-6}>
          <Box
            mx={2}
            sx={{
              // width: '90%'
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 900,
              borderRadius: 1,
              color: '#198754'
            }}
          >
            Trial Subscriptions:
            <TrialSubscriptionStatus />
          </Box>
        </Box>
        <Box p={2}>
          <Button
            variant="contained"
            color="warning"
            size="small"
            // fullWidth
            fullWidth
            onClick={() => handleStore()}
            className="signout-btn"
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </SidebarWrapper>

      {!isDesktop &&
        (
          <Button onClick={() => closeSidebar()}>
            <SubjectIcon className="theme-color" />
          </Button>
        )}


      < Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          className='custom-sidebar-color'
          sx={{
            boxShadow:
              theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52
                }}
              >
                <Logo />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu />
          </Scrollbar>
          <Divider
            sx={{
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <Box mt={-6}>
            <Box
              mx={2}
              sx={{
                // width: '90%'
                textAlign: 'center',
                fontSize: 16,
                fontWeight: 900,
                borderRadius: 1,
                color: '#198754'
              }}
            >
              Trial Subscriptions:
              <TrialSubscriptionStatus />
            </Box>
          </Box>
          <Box p={2}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              fullWidth
              onClick={() => handleStore()}
              className="signout-btn"
            >
              <LockOpenTwoToneIcon sx={{ mr: 1 }} />
              Sign out
            </Button>
          </Box>
        </SidebarWrapper>
      </Drawer >

    </>
  );
}

export default Sidebar;
