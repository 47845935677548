import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Avatar,
  Grid,
  Button
} from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

function EmployerPersonalDetailBox(employerData) {
  const { 
    id, email, employer_name, category, phone_no, country_name, country_code, location,
    is_email_verified, website, hired_worker_count, rating, total_jobs_count
  } = employerData;
  const feed = [
    {
      name: 'Email',
      jobtitle: email,
      // company: 'Email',
      // avatar: '/static/images/avatars/1.jpg'
    },
    {
      name: 'Phone no',
      jobtitle: phone_no,
      // company: 'Buzzdog',
      // avatar: '/static/images/avatars/2.jpg'
    },
    {
      name: 'Category',
      jobtitle: category,
      // company: 'Yozio',
      // avatar: '/static/images/avatars/3.jpg'
    },
    {
      name: 'Location',
      jobtitle: location,
      // company: 'Cogibox',
      // avatar: '/static/images/avatars/4.jpg'
    },
    {
      name: 'Website URL',
      jobtitle: website,
      // company: 'Babbleblab',
      // avatar: '/static/images/avatars/5.jpg'
    },
    {
      name: 'Subscription package',
      jobtitle: '-',
      // company: 'Aimbu',
      // avatar: '/static/images/avatars/6.jpg'
    }
  ];

  return (
    <Card>
      {/* <CardHeader title="Followers Feed" /> */}
      {/* <Divider /> */}
      <Box p={2}>
        <Grid container spacing={0}>
          {feed.map((_feed) => (
            <Grid key={_feed.name} item xs={12} sm={6} lg={6}>
              <Box p={3} display="flex" alignItems="flex-start">
                {/* <Avatar src={_feed.avatar} /> */}
                <Box pl={2}>
                  {/* <Typography gutterBottom variant="subtitle2">
                    {_feed.company}
                  </Typography> */}
                  <Typography variant="h4" gutterBottom>
                    {_feed.name}
                  </Typography>
                  <Typography color="text.primary" sx={{ pb: 1, wordBreak: 'break-all' }}>
                    {_feed.jobtitle}
                  </Typography>
                  {/* <Button
                    variant="outlined"
                    size="small"
                    startIcon={<AddTwoToneIcon />}
                  >
                    Follow
                  </Button> */}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  );
}

export default EmployerPersonalDetailBox;

