import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Box, Container, Grid } from '@mui/material';
import Footer from 'src/components/Footer';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest, searchPostRequest } from 'src/data/helpers/services';
import { EMPLOYER_LIST_API } from 'src/constants';
import {
    employerListContentData,
    employerListContentDataModified
} from 'src/data/mappers/EmployerList';
import { InsertEmployerListContent } from 'src/store/employers';
import EmployerListDataTable from '../pages/Components/employers/EmployersDatatable';

import axios from 'axios';
import { ShowSuccessAlert } from 'src/store/alerts';

// import { Fernet } from 'fernet-nodejs';

// import { Fernet } from 'cryptography';

// import CryptoJS from 'crypto-js';

// import sjcl from 'sjcl';


// import CryptoES from 'crypto-es';

// import { AES } from 'crypto-js';
import useResponsive from 'src/hooks/useResponsive';


function EmployerList() {
    // const fernetKey = '-QvqDMp_suARhL46drZhcEIT-7eA4Dhd6dIXqZO0a4U=';
    // const encryptedMessage = 'gAAAAABkfu23gpZhdQPmyNeDVH0lFyYQ-ZUPsiV_uWdP9E2Reby-psm_mAuk9JLwZoajm8jCuw6-23UL4Kayt8iH5Hp8wB-LQXwjymgfMlA3RjADF4RhrrLRKaD45UDHamxyBC8sFu27';

    // const f = new Fernet("y1sX5SjnXtlaxTOpKu8ci5i_BZJIPU8uqcV_8DgaMEQ=");
    // const decryptedBytes = CryptoES.AES.decrypt(encryptedMessage, fernetKey);

    // // const decryptedMessage = sjcl.decrypt(fernetKey, encryptedMessage);

    // const decryptedMessage = decryptedBytes.toString(CryptoES.enc.Utf8);
    // console.log(decryptedMessage);

    // const decryptedText = AES.decrypt("QlbCOGG70q5Fcxy27faXIQ==", "getsgshsyejehebe").toString(CryptoJS.enc.Utf8);
    // console.log("decryptedText", decryptedText)

    // const fernet = new Fernet(fernetKey);

    // const plainText = f.decrypt(encryptedMessage);
    // console.log("plainText",plainText)

    const dispatch = useDispatch();
    let navigate = useNavigate();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    let employerListContent = useSelector((state: RootState) => state.employers.employerListContent);

    const [employersCount, setEmployersCount] = useState(0);

    const savedPageNumber = localStorage.getItem('employerPageNumber');

    var currentPageNumber = 0;
    if (savedPageNumber && savedPageNumber !== null) {
        const current_page = Number(savedPageNumber);
        if (current_page >= 0) {
            currentPageNumber = current_page;
        }
    }

    const [controller, setController] = useState({
        // pageNumber: 0,
        pageNumber: currentPageNumber,
        pageLimit: 10
    });

    const [searchTerm, setSearchTerm] = useState('');

    const [sortField, setSortField] = useState('');

    const [sortOrder, setSortOrder] = useState('');

    const [loading, setLoading] = useState(false);

    const [isRowSelected, setIsRowSelected] = useState("");

    // useRef keeps track of the current cancel token
    const sourceRef = useRef(null);

    useEffect(() => {
        getEmployerList();
    }, [controller, searchTerm, sortOrder]);

    const getEmployerList = async () => {
        setLoading(true);


        // We are creating a new cancel token and saving it to the sourceRef ref before making
        // the API request & passing this cancelToken to axios.
        // By using this if a new search is triggered before the previous one has completed
        // older API requests will be cancelled from promise & result of latest request will
        // be returned
        if (sourceRef.current) {
            sourceRef.current.cancel('Operation canceled by the user.');
        }

        const source = axios.CancelToken.source();
        sourceRef.current = source;

        let res: any = {};
        res = await searchPostRequest(
            EMPLOYER_LIST_API,
            {
                page_number: controller.pageNumber,
                page_limit: controller.pageLimit,
                search_text: searchTerm,
                sort_field: sortField,
                sort_order: sortOrder
            },
            token,
            source.token,
        );

        if (res) {
            if (isApiCodeSucess(res)) {
                let formattedEmployerListContentData = res.data?.data.employers as employerListContentData;
                let formattedEmployerListContent: employerListContentDataModified = [];
                formattedEmployerListContentData.forEach((x) => {
                    formattedEmployerListContent.push({
                        id: x.id,
                        employer_name: x.employer_name,
                        email: x.email,
                        phone_no: x.phone_no,
                        country_name: x.country_name,
                        location: x.location,
                        is_email_verified: x.is_email_verified,
                        is_phone_verified: x.is_phone_verified,
                        is_profile_created: x.is_profile_created,
                        created_at: x.created_at,
                        user_id: x.user_id,
                        is_blocked: x.is_blocked,
                        subscription: x.subscription,
                        extras: {
                            controller: controller,
                            searchTerm: searchTerm,
                            setEmployersCount: setEmployersCount
                        }
                    });
                });
                dispatch(InsertEmployerListContent(formattedEmployerListContent));
                setEmployersCount(res.data?.data.employers_count);
            }
        }
        setLoading(false);
    }

    const isDesktop = useResponsive('up', 'lg', '');

    const employerDatatableProps = {
        employerListContent: employerListContent,
        employersCount: employersCount,
        setEmployersCount: setEmployersCount,
        controller: controller,
        setController: setController,
        searchTerm: searchTerm,
        setSearchTerm: setSearchTerm,
        loading: loading,
        setLoading: setLoading,
        sortField: sortField,
        setSortField: setSortField,
        sortOrder: sortOrder,
        setSortOrder: setSortOrder,
        isRowSelected: isRowSelected,
        setIsRowSelected: setIsRowSelected,
        isDesktop: isDesktop,
    }

    // useEffect(() => {
    //     if (isRowSelected === "no") {
    //         dispatch(ShowErrorAlert({
    //           visible:true,
    //           message: "Select user rows to perform any action."
    //         }));
    //         setIsRowSelected("");
    //     } 
    // }, [isRowSelected]);

    return (
        <>
            <Box
                className=""
                sx={{
                    marginTop: "-90px"
                }}
            >
                {/* <Box className='dashboard__topbar' mt={2}>
                  <Box component="h2" className="dashboard__topbar--heading">
                      Employers
                  </Box>
              </Box> */}
                <Box
                    className='position--relative'
                    // mt={-5}
                    mt={2}
                >
                    {/* <Box className='table__actionbuttons'>
                      <button className='saveBtn shrinkbtn'>Add New</button>
                  </Box> */}
                    <Box className='dashboard__content'>
                        <EmployerListDataTable {...employerDatatableProps} />
                    </Box>
                </Box>
            </Box>
            {/* <Helmet>
          <title>Quickwork: Employers</title>
        </Helmet>
        <PageTitleWrapper>
          <PageHeader title={"Dashboard"}/>
        </PageTitleWrapper>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12}>
              <Box className="">
                  <Box className='dashboard__topbar'>
                      <Box component="h2" className="dashboard__topbar--heading">
                          Employers
                      </Box>
                  </Box>
                  <Box className='position--relative'>
                      <Box className='dashboard__content'>
                        <EmployerListDataTable employerListContent={employerListContent}/>
                      </Box>
                  </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Footer /> */}
        </>
    );
}

export default EmployerList;

