import { DataGrid, GridRowsProp, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import { Link } from 'react-router-dom';

// import { categoryListContentDataModified } from '../../../data/mappers/categoryList';
import { skillListContentDataModified } from 'src/data/mappers/SkillList';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { number } from 'prop-types';
import { debounce } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { postRequest } from 'src/data/helpers/services';
import { SKILL_STATUS_UPDATE_API } from 'src/constants';
import { ShowSuccessAlert } from 'src/store/alerts';
import { SkillStatusUpdate } from 'src/store/skills';
import SkillAdd from './SkillAddForm';


function BlockSkill(params: any) {
    params = params.params;
    let dispatch = useDispatch()
    const [updating, setUpdating] = React.useState<boolean>(false)

    const [skillBlockStatus, setSkillBlockStatus] = React.useState<boolean>(params.row.is_blocked)

    let accessToken = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );
    const skillBlocked = params.row.is_blocked;

    const handleClick = async () => {
        setSkillBlockStatus(!skillBlockStatus)
        if (!updating) {
            setUpdating(true);

            const res: any = await postRequest(
                SKILL_STATUS_UPDATE_API, {
                skill_id: params.row.id
            },
                accessToken
            );
            if (res) {
                dispatch(ShowSuccessAlert({ visible: true, message: "Skill status updated successfully" }));
                dispatch(SkillStatusUpdate(params.row.id))
            }
            setUpdating(false);
        }
    }

    return (
        skillBlockStatus
            ?
            <Button
                sx={{
                    "marginTop": "-3px", "background": "#d4e5d5",
                    "borderColor": "#d4e5d5", "color": "#b56385",
                    "width": "90px", "marginLeft": "10px"
                }}
                onClick={handleClick}
            >
                UnBlock
            </Button>
            :
            <Button
                sx={{
                    "marginTop": "-3px", "background": "rgb(48 51 72 / 10%)",
                    "borderColor": "#d4e5d5", "color": "#4a84cd",
                    "width": "90px", "marginLeft": "10px"
                }}
                onClick={handleClick}
            >
                Block
            </Button>

    );
}


const columns: GridColDef[] = [
    {
        field: 'id', headerName: 'ID', width: 0, hide: true
    },
    {
        field: 'title', headerName: 'English',
        headerClassName: 'super-app-theme--header', type: 'string',
        flex: 0.4, minWidth: 200, headerAlign: 'center', align: 'center', filterable: true
    },
    {
        field: 'translated_skill', headerName: 'Spanish',
        headerClassName: 'super-app-theme--header', type: 'number',
        minWidth: 200,
        flex: 0.4, headerAlign: 'center', align: 'center'
    },
    {
        field: 'is_blocked', headerName: 'Action', headerClassName: 'super-app-theme--header',
        type: 'string', sortable: false, flex: 0.2, minWidth: 200,
        renderCell: (params: GridRenderCellParams<boolean>) => (
            <>
                <ButtonGroup className="btn_group" size="small" aria-label="small button group">
                    <button className="edit_btn"
                        onClick={() => {
                            let extras = params.row.extras;
                            extras.setSkillId(params.row.id)
                            extras.setExistingTitle(params.row.title)
                            extras.setExistingTitleSpanish(params.row.translated_skill)
                            extras.setModeType("Edit")
                            extras.setOpen(true)
                        }}
                    >
                        <EditOutlinedIcon />
                    </button>
                    &nbsp;
                </ButtonGroup>
                <BlockSkill params={params} />
            </>
            // <>
            //     <ButtonGroup size="small" aria-label="small button group">
            //         <Link to={`/admin/employers/${params.row.id}/`} style={{ textDecoration: 'none' }}>
            //             <Button sx={{
            //                 "marginTop": "-3px", "background": "#d4e5d5",
            //                 "borderColor": "#d4e5d5", "color": "#b56385"
            //             }}>Edit</Button>
            //         </Link>
            //         <Link to={`/admin/${params.row.id}/`} style={{ textDecoration: 'none' }}>
            //             <Button sx={{"marginTop": "-3px", "marginLeft": "5px"}}>Block</Button>
            //         </Link>
            //     </ButtonGroup>
            // </>
        )
    }
]

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{ mr: 2 }} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}


interface controllerInterface {
    pageNumber: number,
    pageLimit: number
}


interface skillListContentDataTableProps {
    skillListContent: skillListContentDataModified;
    skillsCount: number,
    setSkillsCount: any,
    controller: controllerInterface,
    setController: any,
    searchTerm: string,
    setSearchTerm: any,
    loading: boolean,
    setLoading: any
}

const SkillListDataTable = (skillDatatableProps: skillListContentDataTableProps) => {
    // //@ts-ignore
    // const classes = useStyles();

    let {
        skillListContent,
        skillsCount,
        setSkillsCount,
        controller,
        setController,
        searchTerm,
        setSearchTerm,
        loading,
        setLoading
    } = skillDatatableProps;

    const handlePageChange = (newPage: number) => {
        setController({
            ...controller,
            pageNumber: newPage
        });
        localStorage.setItem('skillPageNumber', String(newPage));
    };

    const handleChangeRowsPerPage = (newPageSize: number) => {
        setController({
            ...controller,
            pageLimit: newPageSize
        });
    };

    // To save search text in state so that on page reload text remains in search text as it is
    useEffect(() => {
        const savedSearchText = localStorage.getItem('skillSearchText');
        if (savedSearchText !== null) {
            setSearchTerm(savedSearchText);
        }
    }, []);

    const [rows, setRows] = React.useState<skillListContentDataModified>(skillListContent);

    const handleSearch = (searchValue: string) => {
        localStorage.setItem('skillSearchText', searchValue);
        // debouncedSearch(searchValue);
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = skillListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    };

    const debouncedSearch = useMemo(() => debounce((searchValue: string) => {
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = skillListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    }, 10), []); // Set the debounce delay to 500ms.

    useEffect(() => {
        setRows(skillListContent);
    }, [skillListContent]);

    return (
        <>
            {/* client side pagination */}
            {/* <div className='datagrid__layout' style={{ height: 750, width: '100%' }}>
                <DataGrid
                    className='datagrid--custom'
                    components={{  Toolbar: QuickSearchToolbar }}
                    rows={rows}
                    columns={columns}
                    // pageSize={10}
                    // rowsPerPageOptions={[10]}
                    density={'comfortable'}
                    autoPageSize={true}
                    componentsProps={{ 
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                    // sx={{
                    //     '.MuiDataGrid-columnSeparator': {
                    //       display: 'none',
                    //     },
                    //     '&.MuiDataGrid-root': {
                    //       border: 'none',
                    //     },
                    //   }}
                />
            </div> */}

            {/* server side
            
            pagination */}


            <div className='datagrid__layout' style={{ height: 750, width: '100%' }}>
                <DataGrid
                    // sx={{
                    //     // border: 0, // also tried setting to none 
                    //     // borderRadius: 2,
                    //     // p: 2,
                    //     // minWidth: 300,
                    //     outline: "none"
                    // }}

                    // className='datagrid--custom {classes.root}'
                    className='datagrid--custom'
                    sx={{
                        padding: "0px !important",
                        // overflowx: 'scroll',
                        minHeight: "100vh"
                    }}
                    // '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                    //     outline: 'none',
                    // },
                    components={{ Toolbar: QuickSearchToolbar }}

                    paginationMode='server'
                    rowCount={skillsCount}
                    rowsPerPageOptions={[controller.pageLimit]}
                    pagination
                    onPageChange={(newPage) => handlePageChange(newPage)}
                    onPageSizeChange={(newPageSize) => handleChangeRowsPerPage(newPageSize)}
                    rows={rows}
                    columns={columns}
                    page={controller.pageNumber}
                    pageSize={controller.pageLimit}
                    density={'comfortable'}
                    loading={loading}
                    // autoPageSize={true}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => handleSearch(event.target.value),
                            clearSearch: () => handleSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
};

export default SkillListDataTable;

