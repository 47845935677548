import {
  Box,
  Card,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { WORKER_DETAIL_API } from 'src/constants';
import { WorkerDetailInterface } from 'src/data/mappers/WorkerDetail';
import WorkerBasicDetail from '../pages/Components/workers/WorkerBasicDetail';
import WorkerWorkDetail from '../pages/Components/workers/WorkerWorkDetail';
import { Link } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Input = styled('input')({
  display: 'none'
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: -${theme.spacing(9)};
    margin-left: ${theme.spacing(2)};

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const CardCover = styled(Card)(
  ({ theme }) => `
    position: relative;

    .MuiCardMedia-root {
      height: ${theme.spacing(26)};
    }
`
);

const CardCoverAction = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
`
);


const setSkillAgePaymentPreference = async (worker_data) => {
  const worker_payment_method_prefernces = worker_data.worker_payment_method_prefernces;
  const payment_method_preference = worker_data.payment_method_preference;

  const workerPaymentPrefernces = [];

  var paymentPreference = "";
  
  if (worker_payment_method_prefernces){
      // worker_payment_prefernces = worker_payment_method_prefernces.filter(function(method) {
      //     return method;
      // )}
      for (let index = 0; index < worker_payment_method_prefernces.length; index++) {
          const element = worker_payment_method_prefernces[index];
          if (element == "1") {
              // [...workerPaymentPrefernces, "Cash"];
              workerPaymentPrefernces.push("Cash");
          }
          else if (element == "2") {
              workerPaymentPrefernces.push("E-Transfer");
          }
          else if (element == "3") {
              workerPaymentPrefernces.push("Stripe");
          }
      }  
  }

  if (payment_method_preference) {
      if (payment_method_preference === "1"){
          paymentPreference = "Cash";
      }
      else if (payment_method_preference === "2"){
          paymentPreference = "Stripe";
      }
  }

  worker_data['workerPaymentPrefernces'] = workerPaymentPrefernces;
  worker_data['paymentPreference'] = paymentPreference;

  var boxShadow = "0 0 5px rgba(0, 0, 0, 0.2)";
  if (!paymentPreference && workerPaymentPrefernces.length === 0){
        boxShadow = 'none';
  }
  worker_data['boxShadow'] = boxShadow;
  return worker_data;
};


const WorkerDetail = () => {
    const { workerId } = useParams();

    const worker_id = workerId;
    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );
    const [workerData, setWorkerData] = useState<WorkerDetailInterface>();

    const [isDetailPageLoaded, setIsDetailPageLoaded] = useState(true);
    const navigate = useNavigate();

    const getWorkerDetail = async (id: number|undefined) => {
        const response = await postRequest(
            WORKER_DETAIL_API,
            { worker_id: id },
            token
        );
        if (response) {
            if (isApiCodeSucess(response)) {
                const response_data = response.data?.data;
                const worker_data = response_data.worker;
                const processed_worker_data = await setSkillAgePaymentPreference(worker_data);
                // const trail_subscription = response_data.trail_subscription;
                setWorkerData(processed_worker_data);
            }
        }
    }
    useEffect(() => {
            getWorkerDetail(parseInt(workerId));
    }, []);
  
//   const employerJobsListProps = {
//     employerId: emp_id,
//     isDetailPageLoaded: isDetailPageLoaded,
//     setIsDetailPageLoaded: setIsDetailPageLoaded,
//     total_jobs_count: employerData ? employerData.total_jobs_count : 0,
//     total_jobs: employerData ? employerData.total_jobs : []
//   }

  return (
  <>
      {/* <Helmet>
        <title>User Details - Management</title>
      </Helmet> */}
      <Container
        sx={{
          mt: -5,
        }}
        maxWidth={false}
        // maxWidth="lg"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          // sx={{width: "100%"}}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              marginTop: "-35px"
            }}
          >
              <Typography variant="h3" noWrap>
                <Link
                    to={`/admin/workers/`}
                    style={{
                      textDecoration: 'none',
                      fontSize: "16px",
                      // color: "#198754 !important"
                      color: "rgb(91 138 116 / 79%)"
                    }}
                >
                    Workers
                </Link>

                <FiberManualRecordIcon
                  fontSize='small'
                  color='disabled'
                  sx={{
                    marginLeft: "15px",
                    fontSize: "12px"
                  }}
                />
                <span
                  style={{
                    fontSize: "15px",
                    marginLeft: "15px",
                    color: "rgb(112,121,129)"
                  }}
                >
                  Worker Detail
                </span>
              </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
                <WorkerBasicDetail {...workerData}/>
              {/* <EmployerDetailCards {...employerData} /> */}
          </Grid>
          <Grid item xs={12} md={12}>
              <WorkerWorkDetail {...workerData} />
          </Grid>
          <Grid item xs={12} md={12} sx={{width: "100%"}}>
              {/* <EmployerJobTabs {...employerJobsListProps}/> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

// ProfileCover.propTypes = {
//   // @ts-ignore
//   user: PropTypes.object.isRequired
// };

export default WorkerDetail;

