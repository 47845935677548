import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { spamTextListContentDataModified } from 'src/data/mappers/SpamTextList';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, debounce } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { SPAM_TEXT_DELETE_API, SPAM_TEXT_STATUS_UPDATE_API } from 'src/constants';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from 'src/store/alerts';
import { SpamTextStatusUpdate } from 'src/store/spamText';


function DeleteButton(params: any) {
    const controller = params.extras.controller;
    const page_number = controller ? controller.pageNumber : 0;
    const page_limit = controller ? controller.pageLimit : 10;
    const search_text = params.extras ? params.extras.searchTerm : "";
    console.log("params.extras",params.extras)
    // const setRefreshTable = params.extras ? params.extras.setRefreshTable : false;

    const [open, setOpen] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)

    const [dialogTitle, setDialogTitle] = React.useState("Spam Phrase Delete Confirmation");
    const [dialogContext, setDialogContext] = React.useState(
        <>
            This action will permanently delete spam phrase:  <b>{params.spam_phrase}</b>
        </>
    );

    let dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    const deleteConfirmed = async () => {
        dispatch(ShowProgressBar());
        setInProgress(true);
        const request_data = {
            spam_phrase_id: params.id,
            page_number: page_number,
            page_limit: page_limit,
            search_text: search_text,
            confirmation: deleteConfirmation
        }
        const response: any = await postRequest(
            SPAM_TEXT_DELETE_API,
            request_data,
            token
        );

        dispatch(HideProgressBar());
        if (isApiCodeSucess(response)) {
            dispatch(ShowSuccessAlert({visible:true, message: response.data.message}));
            setOpen(false);
            // setRefreshTable(true);
            window.location.reload();
        }
    }
    

    const handleClose = () => {
        setOpen(false);
        setInProgress(false);
        setDeleteConfirmation(false);
        dispatch(HideProgressBar());
    };

    return (
        <div>
            {/* <button
                style={{
                    border: 'none',
                    background: 'none',
                    marginTop: '-10px'
                }}
                // className="position-relative mx-2 each--tooltip btn--statuscheck btn--unblock"
                onClick={handleClickOpen}
            >
                <DeleteForeverIcon sx={{ "color": "#da195f" }} />
            </button> */}

            <Button
                sx={{
                    "marginTop": "-3px", "background": "rgb(48 51 72 / 10%)",
                    "borderColor": "#d4e5d5", "color": "#4a84cd",
                    "width": "90px", "marginLeft": "10px"
                }}
                onClick={handleClickOpen}
            >
                Delete
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {/* <h4>{"Spam Phrase Delete Confirmation"}</h4> */}
                    <span>{dialogTitle}</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContext}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        inProgress
                            ?
                            <span>
                                <h4>Deletion in Progress...</h4>
                            </span>
                            :
                            <>
                                <button className='deleteButton normal--btn cancel-class' onClick={handleClose}>
                                    Cancel
                                </button>

                                <button
                                    className='deleteButton normal--btn deleted-class'
                                    onClick={deleteConfirmed}
                                >
                                    Delete
                                </button>
                            </>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}


const columns: GridColDef[] = [
    {
        field: 'id', headerName: 'ID', width: 0, hide: true
    },
    {
        field: 'spam_phrase', headerName: 'Spam text',
        headerClassName: 'super-app-theme--header', type: 'string',
        flex: 0.4, minWidth: 200, headerAlign: 'center', align: 'center', filterable: true
    },
    {
        field: 'is_blocked', headerName: 'Action', headerClassName: 'super-app-theme--header',
        type: 'string', sortable: false, flex: 0.2, minWidth: 200,
        renderCell: (params: GridRenderCellParams<boolean>) => (
            <>
                <ButtonGroup className="btn_group" size="small" aria-label="small button group">
                    <button className="edit_btn"
                        onClick={() => {
                            let extras = params.row.extras;
                            extras.setSpamTextId(params.row.id)
                            extras.setExistingSpamText(params.row.spam_phrase)
                            extras.setModeType("Edit")
                            extras.setOpen(true)
                            // extras.setRefreshTable(false)
                        }}
                    >
                        <EditOutlinedIcon />
                    </button>
                    &nbsp;
                </ButtonGroup>
                {/* <DeleteSpamText params={params} /> */}
                <DeleteButton {...params.row} />
            </>
        )
    }
]

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
                mt: 2,
                ml: 2
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{ mr: 2 }} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}


interface controllerInterface {
    pageNumber: number,
    pageLimit: number
}


interface spamTextListContentDataTableProps {
    spamTextListContent: spamTextListContentDataModified;
    spamTextCount: number,
    setSpamTextCount: any,
    controller: controllerInterface,
    setController: any,
    searchTerm: string,
    setSearchTerm: any,
    loading: boolean,
    setLoading: any,
    // refreshTable: any,
    // setRefreshTable: any
}

const SpamTextListDataTable = (spamTextDatatableProps: spamTextListContentDataTableProps) => {
    // //@ts-ignore
    // const classes = useStyles();

    let {
        spamTextListContent,
        spamTextCount,
        setSpamTextCount,
        controller,
        setController,
        searchTerm,
        setSearchTerm,
        loading,
        setLoading,
        // refreshTable,
        // setRefreshTable
    } = spamTextDatatableProps;
    const handlePageChange = (newPage: number) => {
        setController({
            ...controller,
            pageNumber: newPage
        });
        localStorage.setItem('spamTextPageNumber', String(newPage));
    };

    const handleChangeRowsPerPage = (newPageSize: number) => {
        setController({
            ...controller,
            pageLimit: newPageSize
        });
    };

    // To save search text in state so that on page reload text remains in search text as it is
    useEffect(() => {
        const savedSearchText = localStorage.getItem('spamTextSearchText');
        if (savedSearchText !== null) {
            setSearchTerm(savedSearchText);
        }
    }, []);

    const [rows, setRows] = React.useState<spamTextListContentDataModified>(spamTextListContent);

    const handleSearch = (searchValue: string) => {
        localStorage.setItem('spamTextSearchText', searchValue);
        // debouncedSearch(searchValue);
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = spamTextListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    };

    const debouncedSearch = useMemo(() => debounce((searchValue: string) => {
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = spamTextListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    }, 10), []); // Set the debounce delay to 500ms.

    useEffect(() => {
        setRows(spamTextListContent);
    }, [spamTextListContent]);

    return (
        <>
            <div
                className='datagrid__layout'
                style={{
                    maxWidth: "100%",
                    marginTop: "2rem"
                }}  
                // style={{ height: 750, width: '100%' }}
            >
                <DataGrid
                    className='datagrid--custom'
                    sx={{
                        padding: "0px !important",
                        // overflowx: 'scroll',
                        minHeight: "95vh"
                    }}
                    // '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                    //     outline: 'none',
                    // },
                    components={{ Toolbar: QuickSearchToolbar }}

                    paginationMode='server'
                    rowCount={spamTextCount}
                    rowsPerPageOptions={[controller.pageLimit]}
                    pagination
                    onPageChange={(newPage) => handlePageChange(newPage)}
                    onPageSizeChange={(newPageSize) => handleChangeRowsPerPage(newPageSize)}
                    rows={rows}
                    columns={columns}
                    page={controller.pageNumber}
                    pageSize={controller.pageLimit}
                    density={'comfortable'}
                    loading={loading}
                    // autoPageSize={true}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => handleSearch(event.target.value),
                            clearSearch: () => handleSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
};

export default SpamTextListDataTable;

