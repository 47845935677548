import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';
import { useState, SyntheticEvent } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from 'src/components/Footer';
import { useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest, searchPostRequest } from 'src/data/helpers/services';
import { JOBS_LIST_API } from 'src/constants';

import { JobListContentData, JobListContentDataModified } from 'src/data/mappers/JobList';
import AdminJobList from './adminJobList';

// import JobList from './jobList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function JobTabs() {
    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
        <Helmet>
            <title>Quickwork | Job Detail</title>
        </Helmet>
        <div
          style={{
              maxWidth: "100%",
              marginTop: "2rem"
          }}
        >
            <div>
              <Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Box sx={{ width: '100%' }}>
                        <Tabs
                            variant="scrollable"
                            scrollButtons="auto"
                            textColor="primary"
                            indicatorColor="primary"
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="All" {...a11yProps(0)} />
                            <Tab label="Upcoming" {...a11yProps(1)} />
                            <Tab label="Ongoing" {...a11yProps(2)} />
                            <Tab label="Ended" {...a11yProps(3)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <AdminJobList jobType="3"/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <AdminJobList jobType="1"/>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <AdminJobList jobType="2"/>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <AdminJobList jobType= "4"/>
                        </TabPanel>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
        </div>
        {/* <Footer /> */}
        </>
    );
}

export default JobTabs;

