import { Box } from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'src/store';
import { isApiCodeSucess, searchPostRequest } from 'src/data/helpers/services';
import { JOB_WORKER_LIST_API, WORKER_LIST_API } from 'src/constants';

import JobWorkerListDataTable from './JobWorkerListDataTable';

import axios from 'axios';
import { workerListContentData, workerListContentDataModified } from 'src/data/mappers/WorkerList';
import { InsertWorkerListContent } from 'src/store/workers';
import { jobWorkerListContentData, jobWorkerListContentModified } from 'src/data/mappers/JobWorkerList';
import useResponsive from 'src/hooks/useResponsive';


function JobWorkerList(props) {
    const job_id = props.jobId;
    const dispatch = useDispatch();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    const [workersCount, setWorkersCount] = useState(0);

    const [workerListContent, setWorkerListContent] = useState<jobWorkerListContentData>(
        []
    );

    const savedPageNumber = localStorage.getItem('jobWorkersPageNumber');

    var currentPageNumber = 0;
    if (savedPageNumber && savedPageNumber !== null) {
        const current_page = Number(savedPageNumber);
        if (current_page >= 0) {
            currentPageNumber = current_page;
        }
    }

    const [controller, setController] = useState({
        // pageNumber: 0,
        pageNumber: currentPageNumber,
        pageLimit: 10
    });

    const [searchTerm, setSearchTerm] = useState('');

    const [loading, setLoading] = useState(false);

    // useRef keeps track of the current cancel token
    const sourceRef = useRef(null);

    useEffect(() => {
        getWorkerList();
    }, [controller, searchTerm]);

    const getWorkerList = async () => {
        setLoading(true);


        // We are creating a new cancel token and saving it to the sourceRef ref before making
        // the API request & passing this cancelToken to axios.
        // By using this if a new search is triggered before the previous one has completed
        // older API requests will be cancelled from promise & result of latest request will
        // be returned
        if (sourceRef.current) {
            sourceRef.current.cancel('Operation canceled by the user.');
        }

        const source = axios.CancelToken.source();
        sourceRef.current = source;

        let res: any = {}
        res = await searchPostRequest(
            JOB_WORKER_LIST_API,
            {
                job_id: job_id,
                page_number: controller.pageNumber,
                page_limit: controller.pageLimit,
                search_text: searchTerm
            },
            token,
            source.token,
        );

        if (res) {
            if (isApiCodeSucess(res)) {
                let formattedWorkerListContentData = res.data?.data.workers as jobWorkerListContentData;
                let formattedWorkerListContent: jobWorkerListContentModified = [];
                formattedWorkerListContentData.forEach((x) => {
                    formattedWorkerListContent.push({
                        id: x.id,
                        profile_pic_path: x.profile_pic_path,
                        first_name: x.first_name,
                        last_name: x.last_name,
                        rating: x.rating,
                        location: x.location,
                        skills: x.skills,
                        worker_job_status: x.worker_job_status,
                        user_id: x.user_id,
                        user_is_blocked: x.user_is_blocked,
                        user_is_email_verified: x.user_is_email_verified,
                        user_created_at: x.user_created_at,
                        phone_no: x.phone_no,
                        is_profile_created: x.is_profile_created,
                        user_is_phone_verified: x.user_is_phone_verified,
                        email: x.email,
                        country_name: x.country_name,
                        no_show_count: x.no_show_count,
                    });
                });
                setWorkerListContent(formattedWorkerListContent);
                setWorkersCount(res.data?.data.workers_count);
            }
        }
        setLoading(false);
    }

    const isDesktop = useResponsive('up', 'lg', '');


    const jobWorkerDatatableProps = {
        workerListContent: workerListContent,
        workersCount: workersCount,
        setWorkersCount: setWorkersCount,
        controller: controller,
        setController: setController,
        searchTerm: searchTerm,
        setSearchTerm: setSearchTerm,
        loading: loading,
        setLoading: setLoading,
        isDesktop: isDesktop
    }

    return (
        <>
            <Box
                sx={{
                    width: "100%"
                }}
            >
                <Box className='position--relative'>
                    <Box
                    // className='dashboard__content'
                    >
                        <JobWorkerListDataTable {...jobWorkerDatatableProps} />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default JobWorkerList;

