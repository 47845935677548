export type skillListContentDataModified = skillListContentDataModifiedObj[]

export interface skillListContentDataModifiedObj {
    id: string;
    title: string;
    translated_skill: string;
    is_blocked: boolean;
    created_at: string;
    extras:any;
}

export type skillListContentData = skillListContentObj[]

export interface skillListContentObj {
    id: string;
    title: string;
    translated_skill: string;
    is_blocked: boolean;
    created_at: string;
    extras:any;
}


export const newSkill = (data:any, skillId:string) => {
    let skillData:any= {
        title: (data.title.trim()),
        spanish_title: (data.title_spanish.trim())
    }
    if (skillId.length > 0) {
        skillData.skill_id = skillId
    }
  
    return skillData;
}

