import PropTypes from 'prop-types';

import {
  DialogContent,
  Box
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { newSpamText } from 'src/data/mappers/SpamTextList';
import { isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { RootState } from 'src/store';
import { SPAM_TEXT_ADD_API, SPAM_TEXT_EDIT_API } from 'src/constants';
import { TextField } from 'src/components/common/TextField';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from 'src/store/alerts';

function SimpleDialog(props: any) {
    const {
        handleSubmit, change, submitting, reset, open, setOpen, onClose,
        modeType, formType, refreshTable, setRefreshTable, spamTextId, existingSpamText
    } = props;

    const dispatch = useDispatch();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    const required = (value:any) => (value ? undefined : 'This field is required')

    const createNewSpamText = async(values:any) => {
        var response: any = null;
        dispatch(ShowProgressBar());
        if (modeType === 'Add' && formType === "SpamPhrase"){
            let data = newSpamText(values, ""); 
            response = await postRequest(SPAM_TEXT_ADD_API, data, token);
        }
        if (modeType === 'Edit' && formType === "SpamPhrase"){
            let data = newSpamText(values, spamTextId);
            console.log("data",data)
            response = await postRequest(SPAM_TEXT_EDIT_API, data, token);
            console.log("response",response)
        }

        if (response) {
            dispatch(HideProgressBar());
            if (isApiCodeSucess(response)) {
                    dispatch(ShowSuccessAlert({visible:true, message: response.data.message}));
                    setOpen(false);
                    setRefreshTable(!refreshTable);
                }
            }
    }

    // const handleClose = () => {
    //     onClose(selectedValue);
    // };

    // const handleListItemClick = (value) => {
    //     onClose(value);
    // };

    return (
        <Dialog
            onClose={() => {setOpen(false)}}
            open={open}
        >
            <DialogTitle>Add Spam Phrase</DialogTitle>
            <DialogContent>
                {
                    open
                    ?
                    <Form
                        onSubmit={createNewSpamText}
                        initialValues={{
                            'spam_phrase': existingSpamText
                        }}
                    >
                        {
                            (props:any) => (
                                <form onSubmit={props.handleSubmit}>
                                    <Box component="div" className="row m-t-30">
                                        <div className='col-12 md-col-12'>
                                            <label className='input--label m-b-10' htmlFor="spam_phrase">
                                                {/* {formType} Title */}
                                                Spam Text
                                            </label>
                                            <Field
                                                validate={required}
                                                name="spam_phrase"
                                                component={TextField}
                                                type="text"
                                                placeholder={`Enter Spam Text here`}
                                                //@ts-ignore
                                                styleName='input--transparent'
                                            />
                                        </div>

                                        <div className="col-12 m-t-30">
                                            <button type="submit" className={`btn--pill saveBtn w-100 w-md-auto`}>
                                            {modeType} Spam Phrase
                                            </button>
                                        </div>
                                    </Box>
                                </form>
                            )
                        }
                    </Form>
                    :
                    ""
                }
            </DialogContent>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired
};


function SpamTextAddForm(props: any) {
    const {
        handleSubmit, change, submitting, reset, open, setOpen, modeType, formType,
        refreshTable, setRefreshTable, spamTextId, existingSpamText
    } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        // setSelectedValue(value);
    };

    return (
        <>
            <SimpleDialog
                //   selectedValue={selectedValue}
                {...props}
                open={open}
                onClose={handleClose}
            />
        </>
    );
}

export default SpamTextAddForm;

