import { Box } from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { isApiCodeSucess, searchPostRequest } from 'src/data/helpers/services';
import { SPAM_TEXT_LIST_API } from 'src/constants';

import axios from 'axios';
import SpamTextListDataTable from '../pages/Components/spamText/spamTextDatatable';
import { spamTextListContentData, spamTextListContentDataModified } from 'src/data/mappers/SpamTextList';
import { InsertSpamTextListContent } from 'src/store/spamText';
import SpamTextAddForm from '../pages/Components/spamText/spamTextAddForm';

function SpamTextList() {
    const dispatch = useDispatch();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    let spamTextListContent = useSelector((state: RootState) => state.spamText.spamTextListContent);

    const [spamTextCount, setSpamTextCount] = useState(0);

    const savedPageNumber = localStorage.getItem('spamTextPageNumber');

    var currentPageNumber = 0;
    if (savedPageNumber && savedPageNumber !== null) {
        const current_page = Number(savedPageNumber);
        if (current_page >= 0) {
            currentPageNumber = current_page;
        }
    }

    const [controller, setController] = useState({
        // pageNumber: 0,
        pageNumber: currentPageNumber,
        pageLimit: 10
    });

    const [searchTerm, setSearchTerm] = useState('');

    const [loading, setLoading] = useState(false);

    // useRef keeps track of the current cancel token
    const sourceRef = useRef(null);

    const [refreshTable,setRefreshTable] = useState<boolean>(false);

    useEffect(() => {
        getSpamTextList();
    }, [controller, searchTerm, refreshTable]);

    const getSpamTextList = async() => {
        setLoading(true);


        // We are creating a new cancel token and saving it to the sourceRef ref before making
        // the API request & passing this cancelToken to axios.
        // By using this if a new search is triggered before the previous one has completed
        // older API requests will be cancelled from promise & result of latest request will
        // be returned
        if (sourceRef.current) {
          sourceRef.current.cancel('Operation canceled by the user.');
        }
  
        const source = axios.CancelToken.source();
        sourceRef.current = source;

        let res:any = {}
        res = await searchPostRequest(
            SPAM_TEXT_LIST_API,
            {
                page_number: controller.pageNumber,
                page_limit: controller.pageLimit,
                search_text: searchTerm
            } ,
            token,
            source.token,
        );

        if (res) {
            if (isApiCodeSucess(res)) {
                let formattedSpamTextListContentData = res.data?.data.spam_phrases as spamTextListContentData;
                let formattedSpamTextListContent: spamTextListContentDataModified = [];
                formattedSpamTextListContentData.forEach((x) => {
                    formattedSpamTextListContent.push({
                        id: x.id,
                        spam_phrase: x.spam_phrase,
                        created_at: x.created_at,
                        extras:{
                            setModeType: setModeType,
                            setOpen: setOpen,
                            setSpamTextId: setSpamTextId,
                            setExistingSpamText: setExistingSpamText
                        }
                    });
                });
                dispatch(InsertSpamTextListContent(formattedSpamTextListContent));
                setSpamTextCount(res.data?.data.spam_phrase_count);
            }
        }
        setLoading(false);
    }

    const spamTextDatatableProps = {
      spamTextListContent: spamTextListContent,
      spamTextCount: spamTextCount,
      setSpamTextCount: setSpamTextCount,
      controller: controller,
      setController: setController,
      searchTerm: searchTerm,
      setSearchTerm: setSearchTerm,
      loading: loading,
      setLoading: setLoading,
      refreshTable: refreshTable,
      setRefreshTable: setRefreshTable,
    }

    const [open, setOpen] = useState<boolean>(false);
    // const [refreshTable,setRefreshTable] = useState<boolean>(false);
    const [modeType,setModeType] = useState<string>("Add");
    const [spamTextId,setSpamTextId] = useState<string>("");
    const [existingSpamText,setExistingSpamText] = useState<string>("");

    let SpamTextFormProps = {
        open: open,
        setOpen: setOpen,
        refreshTable: refreshTable,
        setRefreshTable: setRefreshTable,
    }

    let AddSpamTextProps = {
        ...SpamTextFormProps,
        modeType: modeType,
        formType: "SpamPhrase",
        spamTextId: spamTextId,
        existingSpamText: existingSpamText,
    }

    return (
      <>
          <Box
            className=""
            sx={{
              marginTop: "-90px"
            }}
          >
              <SpamTextAddForm {...AddSpamTextProps}/>
              <Box
                className='position--relative'
                mt={0}
                sx={{width: "100%"}}
              >
                  <Box className='table__actionbuttons'>
                    <button
                        className='saveBtn shrinkbtn'
                        onClick={() => {
                            setModeType("Add")
                            setExistingSpamText("")
                            setOpen(true)
                        }}
                        style={{ marginTop: 20 }}
                    >
                        Add New
                    </button>
                  </Box>
                  <Box className='dashboard__content'>
                    <SpamTextListDataTable {...spamTextDatatableProps} />
                  </Box>
              </Box>
          </Box>
      </>
    );
}

export default SpamTextList;

