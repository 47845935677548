import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Box, Container, Grid } from '@mui/material';
import Footer from 'src/components/Footer';

import JobTabs from '../pages/Components/jobs/JobsTabs';

function JobsList() {

    return (
        <>
            <Box
                className=""
                sx={{
                marginTop: "-90px"
                }}
            >
                {/* <Box className='dashboard__topbar' mt={2}>
                    <Box component="h2" className="dashboard__topbar--heading">
                        Jobs
                    </Box>
                </Box> */}
                <Box
                    className='position--relative'
                    // mt={-7}
                    mt={-11}
                    sx={{width: "100%"}}
                >
                    <Box className='dashboard__content'>
                        <div>
                            <Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                >
                                    <JobTabs />
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default JobsList;

