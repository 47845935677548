import { DASHBOARD_DATA } from "../../constants";
import {
    DashboardDataAction,
    dashboardState
} from "./types";


export const UpdateDashboardContent = (data: dashboardState) : DashboardDataAction => ({
    type: DASHBOARD_DATA,
    data: data
});

