import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  Tooltip,
  Avatar,
  CardMedia,
  Button,
  IconButton,
  Container,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import EmployerList from '.';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { EmployerDetailInterface } from 'src/data/mappers/EmployerDetail';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { EMPLOYER_DETAIL_API } from 'src/constants';
import EmployerDetailCards from '../pages/Components/employers/employerDetailCards';
import { Helmet } from 'react-helmet-async';
import RecentActivity from '../applications/Users/profile/RecentActivity';
// import { Feed } from '@mui/icons-material';
import PopularTags from '../applications/Users/profile/PopularTags';
import MyCards from '../applications/Users/profile/MyCards';
import Addresses from '../applications/Users/profile/Addresses';
import ProfileCover from '../applications/Users/profile/ProfileCover';
import EemployerProfileCover from '../pages/Components/employers/employerProfileCover';
import EmployerPersonalDetailBox from '../pages/Components/employers/employerPersonalDetailBox';
import Feed from '../applications/Users/profile/Feed';
import TabsDemo from '../pages/Components/Tabs';
import EmployerJobTabs from '../pages/Components/employers/employerJobTabs';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Link } from 'react-router-dom';

const Input = styled('input')({
  display: 'none'
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: -${theme.spacing(9)};
    margin-left: ${theme.spacing(2)};

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const CardCover = styled(Card)(
  ({ theme }) => `
    position: relative;

    .MuiCardMedia-root {
      height: ${theme.spacing(26)};
    }
`
);

const CardCoverAction = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
`
);

const EmployerDetail = () => {
    const { employerId } = useParams();

    const emp_id = employerId;
    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );
    const [employerData, setEmployerData] = useState<EmployerDetailInterface>();

    const [isDetailPageLoaded, setIsDetailPageLoaded] = useState(true);
    const navigate = useNavigate();

    const getEmployerDetail = async (id: number|undefined) => {
        const response = await postRequest(
            EMPLOYER_DETAIL_API,
            { employer_id: id },
            token
        );
        if (response) {
            if (isApiCodeSucess(response)) {
                const response_data = response.data?.data;
                const employer_data = response_data.employers;
                // const trail_subscription = response_data.trail_subscription;
                setEmployerData(employer_data);
            }
        }
    }
    useEffect(() => {
            getEmployerDetail(parseInt(employerId));
    }, []);
  // }, []);

  // return (
  //   <>
  //     <Box display="flex" mb={3}>
  //       <Tooltip arrow placement="top" title="Go back">
  //         <IconButton color="primary" sx={{ p: 2, mr: 2 }}>
  //           <ArrowBackTwoToneIcon />
  //         </IconButton>
  //       </Tooltip>
  //       <Box>
  //         <Typography variant="h3" component="h3" gutterBottom>
  //           Employer Detail
  //         </Typography>
  //         <Typography variant="subtitle2">
  //           {employerData ? employerData.employer_name : ""}
  //         </Typography>
  //       </Box>
  //     </Box>
  //     {/* <EmployerDetailCards /> */}
  //     <CardCover>
  //       <CardMedia image={"user.coverImg"} />
  //       <CardCoverAction>
  //         <Input accept="image/*" id="change-cover" multiple type="file" />
  //         <label htmlFor="change-cover">
  //           <Button
  //             startIcon={<UploadTwoToneIcon />}
  //             variant="contained"
  //             component="span"
  //           >
  //             Change cover
  //           </Button>
  //         </label>
  //       </CardCoverAction>
  //     </CardCover>
  //     <AvatarWrapper>
  //       <Avatar variant="rounded" alt={"user.name"} src={"user.avatar"} />
  //       <ButtonUploadWrapper>
  //         <Input
  //           accept="image/*"
  //           id="icon-button-file"
  //           name="icon-button-file"
  //           type="file"
  //         />
  //         <label htmlFor="icon-button-file">
  //           <IconButton component="span" color="primary">
  //             <UploadTwoToneIcon />
  //           </IconButton>
  //         </label>
  //       </ButtonUploadWrapper>
  //     </AvatarWrapper>
  //     <Box py={2} pl={2} mb={3}>
  //       {/* <Typography gutterBottom variant="h4">
  //         {employerData ? employerData.email : ""}
  //       </Typography> */}
  //       {/* <Typography variant="subtitle2">{"user.description"}</Typography>
  //       <Typography sx={{ py: 2 }} variant="subtitle2" color="text.primary">
  //         {"user.jobtitle"} | {"user.location"} | {"user.followers"} followers
  //       </Typography> */}
  //       <Box
  //         display={{ xs: 'block', md: 'flex' }}
  //         alignItems="center"
  //         justifyContent="space-between"
  //       >
  //         <Box>
  //           <EmployerDetailCards />
  //           {/* <Button size="small" variant="contained">
  //             Follow
  //           </Button>
  //           <Button size="small" sx={{ mx: 1 }} variant="outlined">
  //             View website
  //           </Button>
  //           <IconButton color="primary" sx={{ p: 0.5 }}>
  //             <MoreHorizTwoToneIcon />
  //           </IconButton> */}
  //         </Box>
  //         {/* <Button
  //           sx={{ mt: { xs: 2, md: 0 } }}
  //           size="small"
  //           variant="text"
  //           endIcon={<ArrowForwardTwoToneIcon />}
  //         >
  //           See all {user.followers} connections
  //         </Button> */}
  //       </Box>
  //     </Box>
  //   </>
  // );

  const employerJobsListProps = {
    employerId: emp_id,
    isDetailPageLoaded: isDetailPageLoaded,
    setIsDetailPageLoaded: setIsDetailPageLoaded,
    total_jobs_count: employerData ? employerData.total_jobs_count : 0,
    total_jobs: employerData ? employerData.total_jobs : []
  }

  return (
  <>
      {/* <Helmet>
        <title>User Details - Management</title>
      </Helmet> */}
      <Container
        sx={{
          mt: -5,
        }}
        maxWidth={false}
        // maxWidth="lg"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          // sx={{width: "100%"}}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              marginTop: "-35px"
            }}
          >
              <Typography variant="h3" noWrap>
                <Link
                    to={`/admin/employers/`}
                    style={{
                      textDecoration: 'none',
                      fontSize: "16px",
                      // color: "#198754 !important"
                      color: "rgb(91 138 116 / 79%)"
                    }}
                >
                    Employers
                </Link>

                <FiberManualRecordIcon
                  fontSize='small'
                  color='disabled'
                  sx={{
                    marginLeft: "15px",
                    fontSize: "12px"
                  }}
                />
                <span
                  style={{
                    fontSize: "15px",
                    marginLeft: "15px",
                    color: "rgb(112,121,129)"
                  }}
                >
                  Employer Detail
                </span>
              </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
              <EmployerDetailCards {...employerData} />
          </Grid>
          <Grid item xs={12} md={6}>
              <EmployerPersonalDetailBox  {...employerData} />
          </Grid>
          <Grid item xs={12} md={12} sx={{width: "100%"}}>
              <EmployerJobTabs {...employerJobsListProps}/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

// ProfileCover.propTypes = {
//   // @ts-ignore
//   user: PropTypes.object.isRequired
// };

export default EmployerDetail;

