import { Reducer } from "react";
import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../../constants";
import { UserLoginActions, userState } from "./types";


export const initialState:userState = {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    authorization: {
        access_token: "",
        refresh_token: ""
    },
    profile_pic_path: "",
}


export const userReducer: Reducer<userState, UserLoginActions> = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case USER_LOGGED_IN:
            return {
                // @ts-ignore
                ...payload.data,
            };
        case USER_LOGGED_OUT:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

