export const ADMIN_LOGIN = "react_admin/signin/";
export const DASHBOARD_DETAIL_API = "react_admin/dashboard/"
export const EMPLOYER_LIST_API = "react_admin/employers/list/";
export const EMPLOYER_DETAIL_API = "react_admin/employers/detail/";
export const SKILL_LIST_API = "react_admin/skills/list/";
export const SKILL_ADD_API = "react_admin/skills/";
export const SKILL_EDIT_API = "react_admin/skills/edit/";
export const SKILL_STATUS_UPDATE_API = "react_admin/skills/status_update/";
export const EMPLOYER_JOB_LIST_API = "react_admin/employers/jobs/list/";
export const WORKER_LIST_API = "react_admin/workers/list/";
export const WORKER_DETAIL_API = "react_admin/workers/detail/";
export const JOBS_LIST_API = "react_admin/jobs/list/";
export const JOB_WORKER_LIST_API = "react_admin/job/workers_list/";
export const JOB_DETAIL_API = "react_admin/job/detail/";
export const JOB_STATUS_UPDATE_API = "react_admin/job/status/update/";
export const BLOCK_UNBLOCK_USER_API = "react_admin/update_user_block_status/";
export const DELETE_USER_API = "react_admin/delete_user/";
export const DELETE_MULTIPLE_USERS_API = "react_admin/delete_multiple_users/";
export const TRIAL_SUBSCRIPTION_STATUS = "react_admin/admin_trial_subscription/";

// spam phrase API endpoints
export const SPAM_TEXT_LIST_API = "react_admin/spam_phrase/list/";
export const SPAM_TEXT_ADD_API = "react_admin/spam_phrase/";
export const SPAM_TEXT_EDIT_API = "react_admin/spam_phrase/edit/";
export const SPAM_TEXT_STATUS_UPDATE_API = "react_admin/spam_phrase/status_update/";
export const SPAM_TEXT_DELETE_API = "react_admin/spam_phrase/delete/";

