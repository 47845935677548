import { number } from 'prop-types';
import { Reducer } from 'react';
import {
    DELETE_USER_ROW,
    EMPLOYER_LIST_CONTENT_DATA, UPDATE_EMPLOYER_BLOCK_STATUS
} from '../../constants';

import { employerListActions, employerListState } from './types';

export const initialState: employerListState = {
    employerListContent: [],
};

export const categoryListReducer: Reducer<employerListState, employerListActions> = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYER_LIST_CONTENT_DATA:
            return {
                ...state,
                employerListContent: [...(
                    //@ts-ignore
                    action?.data || [])],
            };
        case UPDATE_EMPLOYER_BLOCK_STATUS:
            return {
                ...state,
                skillListContent: state.employerListContent.map(employer => {
                    if (String(employer.id) === String(action.data)) {
                        employer.is_blocked = !employer.is_blocked
                        return {...employer}
                    };
                    return employer;
                }),
                // searchedValue : state.searchedValue
            };
        case DELETE_USER_ROW:
            // return state.employerListContent.filter((data, i) => i !== action.id);
            // return {
            //     ...state,
            //     skillListContent: state.employerListContent.filter((data, i) => i !== action.id)
            //     }),
            //     // searchedValue : state.searchedValue
            // };
            return {
                ...state,
                employerListContent: state.employerListContent.filter(
                    (items) => Number(items?.id) !== Number(action?.id)
                ),
            };
        default:
            return state;
    }
};

