import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useState } from 'react';

import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  DialogContent,
  Box
} from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Footer from 'src/components/Footer';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { newSkill } from 'src/data/mappers/SkillList';
import { isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { RootState } from 'src/store';
import { SKILL_ADD_API, SKILL_EDIT_API } from 'src/constants';
import { TextField } from 'src/components/common/TextField';
import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from 'src/store/alerts';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props: any) {
    const {
        handleSubmit, change, submitting, reset, open, setOpen, onClose,
        modeType, formType, refreshTable, setRefreshTable, skillId, existingTitle, existingTitleSpanish
    } = props;

    const dispatch = useDispatch();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    const required = (value:any) => (value ? undefined : 'This field is required')

    const createNewSkill = async(values:any) => {
        var response: any = null;
        dispatch(ShowProgressBar());
        if (modeType === 'Add' && formType === "Skill"){
            let data = newSkill(values, ""); 
            response = await postRequest(SKILL_ADD_API, data, token);
        }
        if (modeType === 'Edit' && formType === "Skill"){
            let data = newSkill(values, skillId);
            response = await postRequest(SKILL_EDIT_API, data, token);
        }

        if (response) {
            dispatch(HideProgressBar());
            if (isApiCodeSucess(response)) {
                    dispatch(ShowSuccessAlert({visible:true, message: response.data.message}));
                    setOpen(false);
                    setRefreshTable(!refreshTable);
                }
            }
    }

    // const handleClose = () => {
    //     onClose(selectedValue);
    // };

    // const handleListItemClick = (value) => {
    //     onClose(value);
    // };

    return (
        <Dialog
            onClose={() => {setOpen(false)}}
            open={open}
        >
            <DialogTitle>Add Skill</DialogTitle>
            <DialogContent>
                {
                    open
                    ?
                    <Form
                        onSubmit={createNewSkill}
                        initialValues={{
                            title: existingTitle,
                            title_spanish: existingTitleSpanish
                        }}
                    >
                        {
                            (props:any) => (
                                <form onSubmit={props.handleSubmit}>
                                    <Box component="div" className="row m-t-30">
                                        <div className='col-12 md-col-12'>
                                            <label className='input--label m-b-10' htmlFor="skill-title">
                                                {/* {formType} Title */}
                                                Skill
                                            </label>
                                            <Field
                                                validate={required}
                                                name="title"
                                                component={TextField}
                                                type="text"
                                                placeholder={`Enter ${formType} here`}
                                                //@ts-ignore
                                                styleName='input--transparent'
                                            />
                                        </div>

                                        <div className='col-12 md-col-12'>
                                            <br></br>
                                            <label className='input--label m-b-10' htmlFor="skill-title-spanish">
                                                {/* {formType} Title */}
                                                Skill(Spanish)
                                            </label>
                                            <Field
                                                validate={required}
                                                name="title_spanish"
                                                component={TextField}
                                                type="text"
                                                placeholder={`Enter spanish skill here`}
                                                //@ts-ignore
                                                styleName='input--transparent'
                                            />
                                        </div>

                                        <div className="col-12 m-t-30">
                                            <button type="submit" className={`btn--pill saveBtn w-100 w-md-auto`}>
                                            {modeType} {formType}
                                            </button>
                                        </div>
                                    </Box>
                                </form>
                            )
                        }
                    </Form>
                    :
                    ""
                }
            </DialogContent>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired
};


function SkillAddForm(props: any) {
    const {
        handleSubmit, change, submitting, reset, open, setOpen, modeType, formType,
        refreshTable, setRefreshTable, skillId, existingTitle, existingTitleSpanish
    } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        // setSelectedValue(value);
    };

    return (
        <>
            <SimpleDialog
                //   selectedValue={selectedValue}
                {...props}
                open={open}
                onClose={handleClose}
            />
        </>
    );
}

export default SkillAddForm;

