import {
    DELETE_USER_ROW,
    EMPLOYER_LIST_CONTENT_DATA, UPDATE_EMPLOYER_BLOCK_STATUS
} from '../../constants';
import { employerListContentDataModifiedObj } from 'src/data/mappers/EmployerList';
import { EmployerListContentAction } from './types';


export const InsertEmployerListContent = (data: Array<employerListContentDataModifiedObj>): EmployerListContentAction => ({
    type: EMPLOYER_LIST_CONTENT_DATA,
    data,
});

export const UpdateEmployerBlockStatus = (data:  number) => ({
    type: UPDATE_EMPLOYER_BLOCK_STATUS,
    data,
});


export const UpdateEmployerDeleteStatus = (id:  number) => ({
    type: DELETE_USER_ROW,
    id,
});

