import {
    WORKER_LIST_CONTENT_DATA
} from '../../constants';
import { workerListContentDataModifiedObj } from 'src/data/mappers/WorkerList'; 
import { WorkerListContentAction } from './types';


export const InsertWorkerListContent = (data: Array<workerListContentDataModifiedObj>): WorkerListContentAction => ({
    type: WORKER_LIST_CONTENT_DATA,
    data,
});

