import {
    Grid,
    Typography,
    CardContent,
    Card,
    Box,
    Divider,
    Button,
    Link
} from '@mui/material';
import moment from 'moment';
  
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Text from 'src/components/Text';
import Label from 'src/components/Label';
import StarIcon from '@mui/icons-material/Star';
import MailIcon from '@mui/icons-material/Mail';
import VerifiedIcon from '@mui/icons-material/Verified';
import CloseIcon from '@mui/icons-material/Close';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import PlaceIcon from '@mui/icons-material/Place';
import { useEffect, useState } from 'react';
import { JobDetailInterface } from 'src/data/mappers/JobDetail';


function JobPaymentMethodPreferences(jobData: JobDetailInterface) {
    const {
        job_own_payment_method, payment_method_preference
    } = jobData;
    var [returnDiv, setReturnDiv] = useState(<div className="keyskill__lisitings"></div>);
    
    const paymentPrefernces = [];
    useEffect(() => {
        if (payment_method_preference){
            for (let index = 0; index < payment_method_preference.length; index++) {
                const element = payment_method_preference[index];
                if (element == "1") {
                    paymentPrefernces.push("Cash");
                }
                else if (element == "2") {
                    paymentPrefernces.push("E-Transfer");
                }
                else if (element == "3") {
                    paymentPrefernces.push("Stripe");
                }
            }
        }
        else if (paymentPrefernces.length === 0 && job_own_payment_method) {
            if (job_own_payment_method === "1") {
                paymentPrefernces.push("Cash");
            }
            else if (job_own_payment_method === "2") {
                paymentPrefernces.push("Stripe");
            }
        }
        const return_div = <div className="keyskill__lisitings">
            {
                paymentPrefernces
                ?
                paymentPrefernces.map((payment_preference, index) => (
                    <span key={index}>{payment_preference}</span>
                ))
                :
                ""
            }
        </div>;
        setReturnDiv(return_div);
    }, [job_own_payment_method, payment_method_preference]);
    return (
        <>
            {returnDiv}
        </>
    )
}


function JobDescriptionTab(jobData: JobDetailInterface) {
    const {
        start_date, end_date, last_date, start_time, end_time, employer_job_category,
        created_at, wage_type, wage_amount, job_skill, requirements, description, website
    } = jobData;

    const error_img = "/static/images/user-default.png";

    const web_href = website ? "https://"+website : "#";

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={12} textAlign={{ sm: 'left' }}>
                    <Box pr={3} pb={2}>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <p>Start Date</p>
                                <span
                                    style={{
                                        fontWeight: '900',
                                        fontSize: "16px"
                                    }}
                                >
                                    {
                                        start_date
                                        ?
                                        new Date(start_date).toLocaleDateString(
                                            'en-us',
                                            {day:"numeric", year:"numeric", month:"short"}
                                        )
                                        :
                                        ""
                                    }
                                </span>                                
                            </div>
                            <div className='col-lg-4'>
                                <p>End Date</p>
                                <span
                                    style={{
                                        fontWeight: '900',
                                        fontSize: "16px"
                                    }}
                                >
                                    {
                                        end_date
                                        ?
                                        new Date(end_date).toLocaleDateString(
                                            'en-us',
                                            {day:"numeric", year:"numeric", month:"short"}
                                        )
                                        :
                                        ""
                                    }
                                </span>
                            </div>
                            <div className='col-lg-4'>
                                <p>Apply Before Date</p>
                                <span
                                    style={{
                                        fontWeight: '900',
                                        fontSize: "16px"
                                    }}
                                >
                                    {moment(last_date).format('DD MMM, YYYY')}
                                </span>
                            </div>
                        </div>
                        <br/>

                        <div className='row'>
                            <div className='col-lg-4'>
                                <p>Timings</p>
                                <span
                                    style={{
                                        fontWeight: '900',
                                        fontSize: "16px"
                                    }}
                                >
                                    {
                                        start_time
                                        ?
                                        new Date("2023-01-01T" + start_time).toLocaleString(
                                            'en-US', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true
                                            }
                                        ).toLowerCase()
                                        :
                                        ""
                                    }
                                    <span
                                        style={{marginLeft: "3px", marginRight: "3px"}}
                                    >
                                        -
                                    </span>
                                    {
                                        end_time
                                        ?
                                        new Date("2023-01-01T" + end_time).toLocaleString(
                                            'en-US', {
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true
                                            }
                                        ).toLowerCase()
                                        :
                                        ""
                                    }
                                </span>                           
                            </div>

                            <div className='col-lg-4'>
                                <p>Job Category</p>
                                <span
                                    style={{
                                        fontWeight: '900',
                                        fontSize: "16px"
                                    }}
                                >
                                    {employer_job_category}
                                </span>                                
                            </div>

                            <div className='col-lg-4'>
                                <p>Created At</p>
                                <span
                                    style={{
                                        fontWeight: '900',
                                        fontSize: "16px"
                                    }}
                                >
                                    {moment(created_at).format('DD MMM, YYYY')}
                                </span>  
                            </div>
                        </div>

                        <br/>
                        
                        <div className='row'>
                            <div className='col-lg-4'>
                                <p>Payment Method</p>
                                <br/>
                                <JobPaymentMethodPreferences {...jobData} />
                            </div>

                            <div className='col-lg-4'>
                                <p>Payment Amount</p>
                                <p className='simple__textdata'>
                                    {
                                        (wage_type === '1')
                                        ?
                                        <span>
                                            ${wage_amount}/hr
                                        </span>
                                        :
                                        (
                                            (wage_type === '2')
                                            ?
                                            <span>
                                                ${wage_amount}/day
                                            </span>
                                            :
                                            <span></span>
                                        )
                                    }
                                </p>
                            </div>

                            <div className='col-lg-4'>
                                <p>Job Skills</p>
                                {/* <p className="simple__textdata"> */}
                                <br/>
                                <div className="keyskill__lisitings">
                                    
                                    {
                                        job_skill
                                        ?
                                        job_skill.map((skill, index) => (
                                            <span key={index}>{skill.title}</span>
                                        ))
                                        :
                                        <span></span>
                                    }
                                </div>
                                {/* </p> */}
                            </div>
                        </div>
                        
                        <br/>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <p>Requirement</p>
                                        <p className='simple__textdata fix-width-text'>
                                            {
                                                requirements
                                                ?
                                                <span>{requirements}</span>
                                                :
                                                <span>Not Found</span>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <br/>
                                <p>About Us</p>
                                <p className='simple__textdata fix-width-text'>
                                    {
                                        description
                                        ?
                                        <span>{description}</span>
                                        :
                                        <span>Not Found</span>
                                    }
                                </p>
                            </div>

                            <div className='col-lg-12'>
                                <br/>
                                <p>Website URL</p>
                                <p className='simple__textdata'>
                                    {
                                        website
                                        ?
                                        <Link href={web_href}>
                                            {website}
                                        </Link>
                                        :
                                        <span>-</span>
                                    }
                                </p>
                            </div>
                        </div>
                    </Box>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
  
  export default JobDescriptionTab;
  
