import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../../constants";
import {
    UserLoggedInAction,
    UserLoggedOutAction,
    userState
} from "./types";


export const userLoggedIn = (data: userState) : UserLoggedInAction => ({
    type: USER_LOGGED_IN,
    data: data
});


export const userLoggedOut = () : UserLoggedOutAction => ({
    type: USER_LOGGED_OUT
});

