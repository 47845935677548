import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import LoginPage from './components/Login/LoginForm';
import { useSelector } from 'react-redux';
import { RootStateInterface } from './store';
import { userState } from './store/user';
import EmployerList from './content/emploers';
import EmployerDetail from './content/emploers/employerDetail';
import SkillList from './content/skills';
import WorkerList from './content/workers';
import WorkerDetail from './content/workers/workerDetail';
import JobsList from './content/jobs';
import JobDetail from './content/jobs/JobDetail';
import SpamTextList from './content/spamText';
// import { PrivateRoute } from './App';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

export const PrivateRoute = ({ children, ...rest }: any) => {
  const user: userState = useSelector((state: RootStateInterface) => state.user);

  if (((user.authorization.access_token?.length > 0) || (user.authorization.access_token?.length > 0)) === false) {
    return <Navigate to={{ pathname: "/" }} />
  }

  return children;
}

export const IsLoggedIn = ({ children, ...rest }: any) => {
  const user: userState = useSelector((state: RootStateInterface) => state.user);

  if (
      user.authorization.access_token
      &&
      user.authorization.access_token?.length > 0
      &&
      typeof user.authorization.access_token !== "undefined"
  ) {
    return <Navigate to={{ pathname: "/admin/dashboard/" }} />
  }

  return children;
}

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: 
          <IsLoggedIn>
            <LoginPage />
          </IsLoggedIn>
      },
      // {
      //   path: 'overview',
      //   element: <Navigate to="/" replace />
      // },
      // {
      //   path: 'status',
      //   children: [
      //     {
      //       path: '',
      //       element: <Navigate to="404" replace />
      //     },
      //     {
      //       path: '404',
      //       element: <Status404 />
      //     },
      //     {
      //       path: '500',
      //       element: <Status500 />
      //     },
      //     {
      //       path: 'maintenance',
      //       element: <StatusMaintenance />
      //     },
      //     {
      //       path: 'coming-soon',
      //       element: <StatusComingSoon />
      //     }
      //   ]
      // },
      // {
      //   path: '*',
      //   element: <Status404 />
      // }
    ]
  },
  {
    path: 'admin/',
    element: <SidebarLayout />,
    children: [
      {
        path: 'dashboard/',
        element:
          <PrivateRoute>
            <Crypto />
          </PrivateRoute>
      },
      {
        path: 'employers/',
        element:
          <PrivateRoute>
            <EmployerList />
          </PrivateRoute>
      },
      {
        path: 'employers/detail/:employerId/',
        element:
          <PrivateRoute>
            <EmployerDetail />
          </PrivateRoute>
      },
      {
        path: 'workers/',
        element:
          <PrivateRoute>
            <WorkerList />
          </PrivateRoute>
      },
      {
        path: 'workers/detail/:workerId/',
        element:
          <PrivateRoute>
            <WorkerDetail />
          </PrivateRoute>
      },
      {
        path: 'jobs/',
        element:
          <PrivateRoute>
            <JobsList />
          </PrivateRoute>
      },
      {
        path: 'jobs/detail/:jobId/',
        element:
          <PrivateRoute>
            <JobDetail />
          </PrivateRoute>
      },
      {
        path: 'skills/',
        element:
          <PrivateRoute>
            <SkillList />
          </PrivateRoute>
      },
      {
        path: 'excluded_phrases/',
        element:
          <PrivateRoute>
            <SpamTextList />
          </PrivateRoute>
      },
    ]
  },
  // {
  //   path: 'admin/dashboards',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element:
  //         <PrivateRoute>
  //           <Navigate to="crypto" replace />
  //         </PrivateRoute>
  //     },
  //     {
  //       path: 'crypto',
  //       element:
  //         <PrivateRoute>
  //           <Crypto />
  //         </PrivateRoute>
  //     },
  //     {
  //       path: 'messenger',
  //       element: 
  //         <PrivateRoute>
  //           <Messenger />
  //         </PrivateRoute>
  //     }
  //   ]
  // },
  // {
  //   path: 'management',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="transactions" replace />
  //     },
  //     {
  //       path: 'transactions',
  //       element: <Transactions />
  //     },
  //     {
  //       path: 'profile',
  //       children: [
  //         {
  //           path: '',
  //           element: <Navigate to="details" replace />
  //         },
  //         {
  //           path: 'details',
  //           element: <UserProfile />
  //         },
  //         {
  //           path: 'settings',
  //           element: <UserSettings />
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/components',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="buttons" replace />
  //     },
  //     {
  //       path: 'buttons',
  //       element: <Buttons />
  //     },
  //     {
  //       path: 'modals',
  //       element: <Modals />
  //     },
  //     {
  //       path: 'accordions',
  //       element: <Accordions />
  //     },
  //     {
  //       path: 'tabs',
  //       element: <Tabs />
  //     },
  //     {
  //       path: 'badges',
  //       element: <Badges />
  //     },
  //     {
  //       path: 'tooltips',
  //       element: <Tooltips />
  //     },
  //     {
  //       path: 'avatars',
  //       element: <Avatars />
  //     },
  //     {
  //       path: 'cards',
  //       element: <Cards />
  //     },
  //     {
  //       path: 'forms',
  //       element: <Forms />
  //     }
  //   ]
  // }
];

export default routes;
