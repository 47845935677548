import { Reducer } from "react";
import { DASHBOARD_DATA } from "../../constants";
import { DashboardDataAction, dashboardState } from "./types";


export const initialState:dashboardState = {
    employers_count: "",
    workers_count: "",
    jobs_count: "",
    skills_count: "",
}


export const dashboardReducer: Reducer<dashboardState, DashboardDataAction> = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case DASHBOARD_DATA:
            return {
                // @ts-ignore
                ...payload.data,
            };
        default:
            return state;
    }
};

