import {
    SPAM_TEXT_LIST_CONTENT_DATA, SPAM_TEXT_STATUS_UPDATE
} from '../../constants';
import { spamTextListContentDataModifiedObj } from 'src/data/mappers/SpamTextList';
import { SpamTextListContentAction } from './types';


export const InsertSpamTextListContent = (data: Array<spamTextListContentDataModifiedObj>): SpamTextListContentAction => ({
    type: SPAM_TEXT_LIST_CONTENT_DATA,
    data,
});


export const SpamTextStatusUpdate = (data:  number) => ({
    type: SPAM_TEXT_STATUS_UPDATE,
    data,
});

