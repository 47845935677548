import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';
import { useState, SyntheticEvent } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from 'src/components/Footer';
import { useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest, searchPostRequest } from 'src/data/helpers/services';
import { JOBS_LIST_API } from 'src/constants';

import { JobListContentData, JobListContentDataModified } from 'src/data/mappers/JobList';
import AdminJobList from './adminJobList';
import JobDescriptionTab from './JobDescriptionTab';
import JobWorkerList from './JobWorkerList';

// import JobList from './jobList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function JobBasicDetailTabs(jobData: any) {
    const {
        id,
        worker_count
    } = jobData;
    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [workerCount, setWorkerCount] = useState("0");

    useEffect(() => {
      if(worker_count) {
        setWorkerCount(worker_count)
      };
    }, [worker_count]);
    const workers_tab_title = `Workers(${workerCount})`

    return (
        <>
        <Helmet>
            <title>Quickwork | Job Detail</title>
        </Helmet>
        <Container
            maxWidth={false}
        >
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
            >
            <Grid item xs={12}>
                <Card>
                <CardContent>
                    <Box sx={{ width: '100%' }}>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="primary"
                        indicatorColor="primary"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Job Description" {...a11yProps(0)} />
                        <Tab label={workers_tab_title} {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        {/* <AdminJobList jobType="3"/> */}
                        <JobDescriptionTab {...jobData} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <JobWorkerList jobId={id}  />
                    </TabPanel>
                    </Box>
                </CardContent>
                </Card>
            </Grid>
            </Grid>
        </Container>
        {/* <Footer /> */}
        </>
    );
}

export default JobBasicDetailTabs;

