import { Box, Container, Grid } from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest, searchPostRequest } from 'src/data/helpers/services';
import { EMPLOYER_JOB_LIST_API, JOBS_LIST_API } from 'src/constants';
import {
    employerListContentData,
    employerListContentDataModified
} from 'src/data/mappers/EmployerList';

import axios from 'axios';
// import JobListDataTable from './jobListDataTable';
import { employerJobListContentData, employerJobListContentDataModified } from 'src/data/mappers/EmployerJobList';
import { JobListContentData, JobListContentDataModified } from 'src/data/mappers/JobList';
import AdminJobListDataTable from './AdminJobListDatatable';
import useResponsive from 'src/hooks/useResponsive';

function AdminJobList(adminJobsListProps: any) {
    const {
        jobType
    } = adminJobsListProps;

    const dispatch = useDispatch();
    let navigate = useNavigate();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    // let employerListContent = useSelector((state: RootState) => state.employers.employerListContent);

    const savedPageNumber = localStorage.getItem('jobPageNumber');

    var currentPageNumber = 0;
    if (savedPageNumber && savedPageNumber !== null) {
        const current_page = Number(savedPageNumber);
        if (current_page >= 0) {
            currentPageNumber = current_page;
        }
    }

    const [controller, setController] = useState({
        // pageNumber: 0,
        pageNumber: currentPageNumber,
        pageLimit: 10
    });

    const [searchTerm, setSearchTerm] = useState('');

    const [loading, setLoading] = useState(false);

    const [sortField, setSortField] = useState('');

    const [sortOrder, setSortOrder] = useState('');

    const [jobsCount, setJobsCount] = useState(0);

    const [jobListContent, setJobListContent] = useState<JobListContentData>(
        []
    );

    // useRef keeps track of the current cancel token
    const sourceRef = useRef(null);

    useEffect(() => {
        getJobList();

    }, [controller, searchTerm, sortOrder]);

    const getJobList = async () => {
        setLoading(true);


        // We are creating a new cancel token and saving it to the sourceRef ref before making
        // the API request & passing this cancelToken to axios.
        // By using this if a new search is triggered before the previous one has completed
        // older API requests will be cancelled from promise & result of latest request will
        // be returned
        if (sourceRef.current) {
            sourceRef.current.cancel('Operation canceled by the user.');
        }

        const source = axios.CancelToken.source();
        sourceRef.current = source;

        let res: any = {}
        res = await searchPostRequest(
            JOBS_LIST_API,
            {
                job_type: jobType,
                page_number: controller.pageNumber,
                page_limit: controller.pageLimit,
                search_text: searchTerm,
                sort_field: sortField,
                sort_order: sortOrder
            },
            token,
            source.token,
        );
        if (res) {
            if (isApiCodeSucess(res)) {
                let formattedJobListContentData = res.data?.data.jobs as JobListContentData;
                let formattedJobListContent: JobListContentDataModified = [];
                formattedJobListContentData.forEach((x) => {
                    formattedJobListContent.push({
                        id: x.id,
                        title: x.title,
                        location: x.location,
                        category: x.category,
                        total_workers: x.total_workers,
                        wage_amount: x.wage_amount,
                        is_active: x.is_active,
                        is_blocked: x.is_blocked,
                        job_status: x.job_status,
                        created_at: x.created_at,
                        start_date: x.start_date,
                        end_date: x.end_date
                    });
                });
                setJobListContent(formattedJobListContent);
                setJobsCount(res.data?.data.jobs_count);
            }
        }
        setLoading(false);
    }

    const isDesktop = useResponsive('up', 'lg', '');


    const jobsDatatableProps = {
        jobListContent: jobListContent,
        jobsCount: jobsCount,
        setJobsCount: setJobsCount,
        controller: controller,
        setController: setController,
        searchTerm: searchTerm,
        setSearchTerm: setSearchTerm,
        loading: loading,
        setLoading: setLoading,
        sortField: sortField,
        setSortField: setSortField,
        sortOrder: sortOrder,
        setSortOrder: setSortOrder,
        isDesktop: isDesktop,
    }

    return (
        <>
            <Box
                sx={{
                    width: "100%"
                }}
            >
                <Box className='position--relative'>
                    <Box
                    // className='dashboard__content'
                    >
                        <AdminJobListDataTable {...jobsDatatableProps} />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default AdminJobList;

