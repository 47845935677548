import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Box, Container, Grid } from '@mui/material';
import Footer from 'src/components/Footer';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest, searchPostRequest } from 'src/data/helpers/services';
import { WORKER_LIST_API } from 'src/constants';

import WorkerListDataTable from '../pages/Components/workers/WorkersDatatable';

import axios from 'axios';
import { workerListContentData, workerListContentDataModified } from 'src/data/mappers/WorkerList';
import { InsertWorkerListContent } from 'src/store/workers';
import useResponsive from 'src/hooks/useResponsive';

function WorkerList() {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    let workerListContent = useSelector((state: RootState) => state.workers.workerListContent);

    const [workersCount, setWorkersCount] = useState(0);

    const savedPageNumber = localStorage.getItem('workerPageNumber');

    var currentPageNumber = 0;
    if (savedPageNumber && savedPageNumber !== null) {
        const current_page = Number(savedPageNumber);
        if (current_page >= 0) {
            currentPageNumber = current_page;
        }
    }

    const [controller, setController] = useState({
        // pageNumber: 0,
        pageNumber: currentPageNumber,
        pageLimit: 10
    });

    const [searchTerm, setSearchTerm] = useState('');

    const [sortField, setSortField] = useState('');

    const [sortOrder, setSortOrder] = useState('');

    const [loading, setLoading] = useState(false);

    const [isRowSelected, setIsRowSelected] = useState("");

    // useRef keeps track of the current cancel token
    const sourceRef = useRef(null);

    useEffect(() => {
        getWorkerList();
    }, [controller, searchTerm, sortOrder]);

    const getWorkerList = async () => {
        setLoading(true);

        // We are creating a new cancel token and saving it to the sourceRef ref before making
        // the API request & passing this cancelToken to axios.
        // By using this if a new search is triggered before the previous one has completed
        // older API requests will be cancelled from promise & result of latest request will
        // be returned
        if (sourceRef.current) {
            sourceRef.current.cancel('Operation canceled by the user.');
        }

        const source = axios.CancelToken.source();
        sourceRef.current = source;

        let res: any = {}
        res = await searchPostRequest(
            WORKER_LIST_API,
            {
                page_number: controller.pageNumber,
                page_limit: controller.pageLimit,
                search_text: searchTerm,
                sort_field: sortField,
                sort_order: sortOrder
            },
            token,
            source.token,
        );

        if (res) {
            if (isApiCodeSucess(res)) {
                let formattedWorkerListContentData = res.data?.data.workers as workerListContentData;
                let formattedWorkerListContent: workerListContentDataModified = [];
                formattedWorkerListContentData.forEach((x) => {
                    formattedWorkerListContent.push({
                        id: x.id,
                        profile_pic_path: x.profile_pic_path,
                        first_name: x.first_name,
                        last_name: x.last_name,
                        rating: x.rating,
                        location: x.location,
                        skills: x.skills,
                        worker_job_status: x.worker_job_status,
                        user_id: x.user_id,
                        is_blocked: x.is_blocked,
                        is_email_verified: x.is_email_verified,
                        user_created_at: x.user_created_at,
                        phone_no: x.phone_no,
                        is_profile_created: x.is_profile_created,
                        is_phone_verified: x.is_phone_verified,
                        email: x.email,
                        country_name: x.country_name,
                        no_show_count: x.no_show_count,
                        extras: {
                            controller: controller,
                            searchTerm: searchTerm,
                            setWorkersCount: setWorkersCount
                        }
                    });
                });
                dispatch(InsertWorkerListContent(formattedWorkerListContent));
                setWorkersCount(res.data?.data.workers_count);
            }
        }
        setLoading(false);
    }

    const isDesktop = useResponsive('up', 'lg', '');

    const workerDatatableProps = {
        workerListContent: workerListContent,
        workersCount: workersCount,
        setWorkersCount: setWorkersCount,
        controller: controller,
        setController: setController,
        searchTerm: searchTerm,
        setSearchTerm: setSearchTerm,
        loading: loading,
        setLoading: setLoading,
        sortField: sortField,
        setSortField: setSortField,
        sortOrder: sortOrder,
        setSortOrder: setSortOrder,
        isRowSelected: isRowSelected,
        setIsRowSelected: setIsRowSelected,
        isDesktop: isDesktop,

    }

    return (
        <>
            <Box
                className=""
                sx={{
                    marginTop: "-90px"
                }}
            >
                {/* <Box className='dashboard__topbar' mt={2}>
                  <Box component="h2" className="dashboard__topbar--heading">
                      Workers
                  </Box>
              </Box> */}
                <Box
                    className='position--relative'
                    // mt={-5}
                    mt={2}
                >
                    {/* <Box className='table__actionbuttons'>
                      <button className='saveBtn shrinkbtn'>Add New</button>
                  </Box> */}
                    <Box className='dashboard__content'>
                        <WorkerListDataTable {...workerDatatableProps} />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default WorkerList;

