import { Reducer } from 'react';
import {
    SPAM_TEXT_LIST_CONTENT_DATA, SPAM_TEXT_STATUS_UPDATE
} from '../../constants';

import { spamTextListActions, spamTextListState } from './types';

export const initialState: spamTextListState = {
    spamTextListContent: [],
};

export const spamTextListReducer: Reducer<spamTextListState, spamTextListActions> = (state = initialState, action) => {
    switch (action.type) {
        case SPAM_TEXT_LIST_CONTENT_DATA:
            return {
                ...state,
                spamTextListContent: [...(
                    //@ts-ignore
                    action?.data || [])],
            };
        case SPAM_TEXT_STATUS_UPDATE:
            return {
                ...state,
                spamTextListContent: state.spamTextListContent.map(spamText => {
                    if (String(spamText.id) === String(action.data)) {
                        // spamText.is_blocked = !spamText.is_blocked
                        return {...spamText}
                    };
                    return spamText;
                }),
                // searchedValue : state.searchedValue
            }
        default:
            return state;
    }
};

