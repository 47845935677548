import {
    Grid,
    Typography,
    CardContent,
    Card,
    Box,
    Divider,
    Button
  } from '@mui/material';
  
  // @ts-ignore
  import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
  // @ts-ignore
  import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
  import Text from 'src/components/Text';
  import Label from 'src/components/Label';
  // @ts-ignore
  import StarIcon from '@mui/icons-material/Star';
  // @ts-ignore
  import MailIcon from '@mui/icons-material/Mail';
  // @ts-ignore
  import VerifiedIcon from '@mui/icons-material/Verified';
  // @ts-ignore
  import CloseIcon from '@mui/icons-material/Close';
  // @ts-ignore
  import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
  // @ts-ignore
  import PlaceIcon from '@mui/icons-material/Place';
import { useState } from 'react';
  
  function WorkerBasicDetail(workerData: any) {
    const {
        email, full_name, country_code, phone_no, profile_pic_path, location,
        is_email_verified, is_phone_verified, rating, total_jobs_count
    }
    = workerData

    // const [imageSrc, setImageSrc] = useState(profile_pic_path ? profile_pic_path : "");
    // const handleImageError = () => {
    //     const error_img = "/static/images/user-default.png";
    //     setImageSrc({error_img}); // Replace with a placeholder image URL
    // }

    const error_img = "/static/images/user-default.png";

    const profile_pic = profile_pic_path ? profile_pic_path : error_img; 


    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h3" gutterBottom>
                  Worker Detail
                </Typography>
                {/* <Typography variant="subtitle2">
                  Manage informations related to your personal details
                </Typography> */}
              </Box>
              {/* <Button variant="text" startIcon={<EditTwoToneIcon />}>
                Edit
              </Button> */}
            </Box>
            <Divider />
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                        <div className="image-box" style={{
                            display: "inline-block",
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "5px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)"
                        }}>
                            <img
                              src={profile_pic}
                              // onError={handleImageError}
                              alt="worker image"
                              style={{
                                display: "block",
                                width: "200px",
                                height: "220px",
                            }}/>
                        </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9} sx={{lineHeight: "27px"}}>
                    <Text color="black">
                      <b>{full_name}</b>
                      <br/>
                      <StarIcon sx={{color: "#fdd643", fontSize: "21px"}}/>
                      <span style={{marginLeft: "4px"}}>
                          {rating}
                      </span>
                      <br/>
                      <MailIcon
                        sx={{
                          color: "#e2bfbf",
                          fontSize: "19px"
                        }}
                      />
                      <span
                        style={{
                          marginLeft: "4px",
                          marginRight: "4px"
                        }}
                      >
                          {email}
                      </span>
                      {is_email_verified
                          ?
                          <VerifiedIcon
                            sx={{color: "rgb(68, 174, 75)", fontSize: "19px"}}
                          />
                          :
                          <CloseIcon
                            sx={{color: "rgba(255, 72, 66, 1)", fontSize: "19px", fontWeight: "bold"}}
                          />
                      }
                      <br/>
                      <WifiCalling3Icon
                        sx={{
                          color: "#a2bd83",
                          fontSize: "19px"
                        }}
                      />
                      <span
                        style={{
                          marginLeft: "4px",
                          marginRight: "4px"
                        }}
                      >
                          {country_code} {phone_no}
                      </span>
                      {is_phone_verified
                          ?
                          <VerifiedIcon
                            sx={{color: "rgb(68, 174, 75)", fontSize: "19px"}}
                          />
                          :
                          <CloseIcon
                            sx={{color: "rgba(255, 72, 66, 1)", fontSize: "17px", fontWeight: "bold"}}
                          />
                      }
                      <br/>
                      <PlaceIcon
                        sx={{
                          color: "#ca7f24",
                          fontSize: "19px"
                        }}
                      />
                      <span>
                          {location}
                      </span>
                      <br/>
                      <span>
                          <Box
                            border="1px dotted #ccc"
                            padding={1}
                            textAlign="left"
                            width={180}
                          >
                              <span style={{fontSize: "19px", fontWeight: "900"}}>{total_jobs_count}</span>
                              <br/>
                              <span style={{fontSize: "14px"}}>Total Jobs</span>
                          </Box>
                      </span>
                    </Text>
                  </Grid>
                  {/* <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      Date of birth:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>15 March 1977</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      Address:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                      <Text color="black">
                        1749 High Meadow Lane, SEQUOIA NATIONAL PARK, California,
                        93262
                      </Text>
                    </Box>
                  </Grid> */}
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  Account Settings
                </Typography>
                <Typography variant="subtitle2">
                  Manage details related to your account
                </Typography>
              </Box>
              <Button variant="text" startIcon={<EditTwoToneIcon />}>
                Edit
              </Button>
            </Box>
            <Divider />
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      Language:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>English (US)</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      Timezone:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>GMT +2</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      Account status:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Label color="success">
                      <DoneTwoToneIcon fontSize="small" />
                      <b>Active</b>
                    </Label>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  Email Addresses
                </Typography>
                <Typography variant="subtitle2">
                  Manage details related to your associated email addresses
                </Typography>
              </Box>
              <Button variant="text" startIcon={<EditTwoToneIcon />}>
                Edit
              </Button>
            </Box>
            <Divider />
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      Email ID:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>example@demo.com</b>
                    </Text>
                    <Box pl={1} component="span">
                      <Label color="success">Primary</Label>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      Email ID:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>demo@example.com</b>
                    </Text>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    );
  }
  
  export default WorkerBasicDetail;
  
