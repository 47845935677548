import { Store} from "redux";
// import rootReducer from "../reducers";
import rootReducer from "./reducer";
import thunk from "redux-thunk";
import { persistReducer, Persistor } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootStateInterface } from "./RootState";
import { configureStore } from '@reduxjs/toolkit'

export interface StoreConfig {
  store: Store<RootStateInterface>;
  persistor: Persistor;
}

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "form",
    "match",
    "alerts"
  ]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export default store;

