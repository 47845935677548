import {
    Grid,
    Typography,
    CardContent,
    Card,
    Box,
    Divider,
    Button
} from '@mui/material';
  
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Text from 'src/components/Text';
import Label from 'src/components/Label';
import StarIcon from '@mui/icons-material/Star';
import MailIcon from '@mui/icons-material/Mail';
import VerifiedIcon from '@mui/icons-material/Verified';
import CloseIcon from '@mui/icons-material/Close';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import PlaceIcon from '@mui/icons-material/Place';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function JobBasicDetailCard(jobData: any) {
    const {
        title, employer_name, is_active, is_blocked, job_status,
    } = jobData;

    const error_img = "/static/images/user-default.png";

    return (
      <Grid container spacing={3}>
        <Grid
            item
            xs={12}
            md={12}
            sx={{
              marginTop: "-35px"
            }}
          >
              <Typography variant="h3" noWrap>
                <Link
                    to={`/admin/jobs/`}
                    style={{
                      textDecoration: 'none',
                      fontSize: "16px",
                      // color: "#198754 !important"
                      color: "rgb(91 138 116 / 79%)"
                    }}
                >
                    Jobs
                </Link>

                <FiberManualRecordIcon
                  fontSize='small'
                  color='disabled'
                  sx={{
                    marginLeft: "15px",
                    fontSize: "12px"
                  }}
                />
                <span
                  style={{
                    fontSize: "15px",
                    marginLeft: "15px",
                    color: "rgb(112,121,129)"
                  }}
                >
                  Job Detail
                </span>
              </Typography>
          </Grid>
        <Grid item xs={12}>
          <Card>
            {/* <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h3" gutterBottom>
                  Job Detail
                </Typography>
              </Box>
            </Box> */}
            <Divider />
            <CardContent sx={{ p: 3 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={12} textAlign={{ sm: 'left' }}>
                    <Box pr={3} pb={2}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p>
                                    <span
                                        style={{
                                            fontWeight: '900',
                                            fontSize: "16px"
                                        }}
                                    >
                                        Job Status:
                                    </span> 
                                    {
                                        job_status === '4'
                                        ?
                                        <span className="completed--badge ms-2">
                                            Ended
                                        </span>
                                        :
                                        (
                                            job_status === '1'
                                            ?
                                            <span className="upcoming--badge ms-2">
                                                Upcoming
                                            </span>
                                            :
                                            (
                                                job_status === '2'
                                                ?
                                                <span className="ongoing--badge ms-2">
                                                    Ongoing
                                                </span>
                                                :
                                                (
                                                    <span className="pending--badge ms-2">
                                                        {job_status}
                                                    </span>
                                                )   
                                            )
                                        )
                                    }
                                </p>
                                <p
                                    style={{
                                        marginTop: '30px'
                                    }}
                                >
                                    <h4 style={{fontWeight: "1000"}}>
                                        {title}
                                    </h4>
                                    <label style={{fontSize: "18px"}}>{employer_name}</label>
                                </p>
                            </div>
                            <div className='col-lg-6' style={{textAlign: "right"}}>
                                <p>
                                    <span
                                        style={{
                                            fontWeight: '900',
                                            fontSize: "16px"
                                        }}
                                    >
                                        Listed: 
                                    </span>
                                    {
                                        is_active
                                        ?
                                        <span className="completed--badge ms-2">
                                            Yes
                                        </span>
                                        :
                                        <span className="pending--badge ms-2">
                                            No
                                        </span>
                                    }
                                </p>
                                <br/>
                                <p>
                                    <span
                                        style={{
                                            fontWeight: '900',
                                            fontSize: "16px"
                                        }}
                                    >
                                        Blocked: 
                                    </span> 
                                    {
                                        is_blocked
                                        ?
                                        <span className="pending--badge ms-2">
                                            Yes
                                        </span>
                                        :
                                        <span className="completed--badge ms-2">
                                            No
                                        </span>
                                    }
                                </p>
                            </div>
                        </div>
                    </Box>
                    {/* <Box pr={3} pb={2}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <span
                                    style={{
                                        fontWeight: '900',
                                        fontSize: "16px"
                                    }}
                                >
                                    Blocked: 
                                </span> 
                                {
                                    is_blocked
                                    ?
                                    <span className="pending--badge ms-2">
                                        Yes
                                    </span>
                                    :
                                    <span className="completed--badge ms-2">
                                        No
                                    </span>
                                }
                            </div>
                        </div>
                    </Box> */}
                    {/* <Box pt={1} pr={3} pb={2}>
                        <h4 style={{fontWeight: "1000"}}>
                            {title}
                        </h4>
                        <label style={{fontSize: "18px"}}>{employer_name}</label>
                    </Box> */}
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
  
  export default JobBasicDetailCard;
  
