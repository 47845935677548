import {
    Grid,
    Typography,
    CardContent,
    Card,
    Box,
    Divider,
    Button
  } from '@mui/material';
  
  import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
  import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
  import Text from 'src/components/Text';
  import Label from 'src/components/Label';
  import StarIcon from '@mui/icons-material/Star';
  import MailIcon from '@mui/icons-material/Mail';
  import VerifiedIcon from '@mui/icons-material/Verified';
  import CloseIcon from '@mui/icons-material/Close';
  import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
  import PlaceIcon from '@mui/icons-material/Place';
import { useEffect, useState } from 'react';
import { WorkerDetailInterface } from 'src/data/mappers/WorkerDetail';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';


function PaymentMethodPreferences(workerData: WorkerDetailInterface) {
    const {
        paymentPreference, workerPaymentPrefernces, boxShadow
    } = workerData;
    if (workerPaymentPrefernces) {
        if (workerPaymentPrefernces.length === 0) {
            return <><br/><br/></>
        }
    }
    return (
        <div
            className="image-box keyskill__lisitings"
            style={{
                display: "inline-block",
                // border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: boxShadow
            }}
        >
            {
                workerPaymentPrefernces
                ?
                (
                    workerPaymentPrefernces.map(
                        (worker_payment_method_prefernce, index) => (
                            <span key={index}>
                                {worker_payment_method_prefernce}
                            </span>
                        )
                    )
                )
                :
                (
                    paymentPreference
                    ?
                        <span>{paymentPreference}</span>
                    :
                    <span></span>
                )
            }
        </div>
    )
}


function WorkerSkills(workerData: WorkerDetailInterface) {
    const {
        worker_skills
    } = workerData;

    const [boxShadow, setBoxShadow] = useState("none");

    useEffect(() => {
        if (worker_skills) {
            setBoxShadow('0 0 5px rgba(0, 0, 0, 0.2)');
        }
    }, [worker_skills]);

    if (worker_skills) {
        if (worker_skills.length === 0) {
            return <><br/><br/></>
        }
    }
    return (
        <div className="image-box keyskill__lisitings" style={{
            display: "inline-block",
            // border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: boxShadow
        }}>
            {
                worker_skills
                ?
                worker_skills.map((worker_skill, index) => (
                    <span key={index}>{worker_skill.title}</span>
                ))
                :
                ""
            }
        </div>
    )
}


function WorkerAge(workerData: WorkerDetailInterface) {
    const {
        age_range
    } = workerData;

    const [ageRange, setAgeRange] = useState("");
    const [boxShadows, setBoxShadows] = useState("none");

    useEffect(() => {
        if (age_range == "1") {
            setAgeRange("18-30")
        }
        else if (age_range == "2") {
            setAgeRange("30-40")
        }
        else if (age_range == "3") {
            setAgeRange("40-50")
        }
        else if (age_range == "4") {
            setAgeRange("50+")
        }

        if (age_range) {
            setBoxShadows('0 0 5px rgba(0, 0, 0, 0.2)');
        }
    }, [age_range]);

    return (
        <div className="image-box" style={{
            display: "inline-block",
            // border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: boxShadows
        }}>
            <span>{ageRange}</span>
        </div>
    )
}


function getYearMonth(start_date, end_date) {
    const start_d = new Date(start_date);
    const end_d = new Date(end_date);
    const diffTime = Math.abs(end_d.getTime() - start_d.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    var abs_year = Math.floor(diffDays / 365);
    var abs_month = Math.floor((diffDays % 365) / 30);

    if (!abs_year) {
        abs_year = 0;
    }

    if (!abs_month) {
        abs_month = 0;
    }
    if (abs_year === 0 && abs_month === 0) {
        return "-"
    }
    else {
        const return_year = `${abs_year} year${abs_year > 1 ? 's' : ''}`;
        const return_month = `${abs_month} month${abs_month > 1 ? 's' : ''}`;
        const return_time = `${return_year} ${return_month}`;
        return return_time;
    }
}

function WorkerExperience(workerData: WorkerDetailInterface) {
    const {
        worker_experiences
    } = workerData;

    var return_experiences = [
        <div className="timeline__wrapper" key="no_exp">
            <div className="timeline__item">
                <div className="timeline__item__badge">
                    <span></span>
                </div>
                <div className="timeline__item__content">
                    <h4 className="timeline__item__content--title">Experience</h4>
                    <p className="timeline__item__content--exp mt-2 ps-0">
                        Not Found
                    </p>
                </div>
            </div>
        </div>
    ]

    const [workerExperience, setWorkerExperience] = useState(return_experiences);
    
    useEffect(() => {
        if (worker_experiences) {
            if (worker_experiences.length > 0) {
                return_experiences = worker_experiences.map((worker_exp, index) =>
                    <div className="timeline__wrapper"  key={index}>
                        <div className="timeline__item">
                            <div className="timeline__item__badge">
                                <span></span>
                            </div>
                            <div className="timeline__item__content">
                                <h4 className="timeline__item__content--title fix-width-text">
                                    {worker_exp.title}
                                </h4>
                                <p className="timeline__item__content--exp mt-2">
                                    {/* <i className="bi bi-briefcase me-2"></i> */}
                                    <BusinessCenterOutlinedIcon
                                        className="bi bi-briefcase me-2"
                                        fontSize='small'
                                    />
                                    {getYearMonth(worker_exp.start_date, worker_exp.end_date)}
                                </p>
                                <p className="timeline__item__content--location mt-2">
                                    <svg className="" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="#a1a5b7"></path>
                                        <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="#a1a5b7"></path>
                                    </svg>
                                    {worker_exp.location}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            }
            setWorkerExperience(return_experiences)
        }
    }, [worker_experiences]);
    return (
        <>{workerExperience}</>
    )
}


function WorkerEducation(workerData: WorkerDetailInterface) {
    const {
        worker_educations
    } = workerData;

    var return_educations = [
        <div className="timeline__wrapper" key="no_edu">
            <div className="timeline__item">
                <div className="timeline__item__badge">
                    <span></span>
                </div>
                <div className="timeline__item__content">
                    <h4 className="timeline__item__content--title">Education</h4>
                    <p className="timeline__item__content--exp mt-2 ps-0">
                        Not Found
                    </p>
                </div>
            </div>
        </div>
    ]

    const [workerEducation, setWorkerEducation] = useState(return_educations);
    
    useEffect(() => {
        if (worker_educations) {
            if (worker_educations.length > 0) {
                return_educations = worker_educations.map((worker_edu, index) =>
                    <div className="timeline__wrapper"  key={index}>
                        <div className="timeline__item">
                            <div className="timeline__item__badge">
                                <span></span>
                            </div>
                            <div className="timeline__item__content">
                                <h4 className="timeline__item__content--title fix-width-text">
                                    {worker_edu.title}
                                </h4>
                                <p className="timeline__item__content--exp mt-2">
                                    {/* <i className="bi bi-journal-album me-2"></i>
                                    */}
                                    <LibraryBooksIcon
                                        className="bi bi-journal-album me-2"
                                        fontSize="small"
                                    />
                                    {new Date(worker_edu.start_date).getFullYear()} - {new Date(worker_edu.end_date).getFullYear()}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            };
            setWorkerEducation(return_educations)
        }
    }, [worker_educations]);

    return (
        <>{workerEducation}</>
    )
}


function WorkerDrivingLicence(workerData: WorkerDetailInterface) {
    const {
        driving_licence
    } = workerData;
    var driving_licence_exisis = false;
    if (driving_licence) {
        if (driving_licence.length > 0) {
            driving_licence_exisis = true;
        }
    }
    return (
        <p className="simple__textdata">
            {
                driving_licence_exisis
                ?
                <span className="uploaded ms-2">
                    Uploaded
                </span>
                :
                <span className="not-uploaded ms-2">
                    Not Uploaded
                </span>
            }
        </p>
    )
}


function WorkerCertificates(workerData: WorkerDetailInterface) {
    const {
        worker_certfications
    } = workerData;

    const [workerCertificates, setWorkerCertificates] = useState([]);
    
    useEffect(() => {
        if (worker_certfications) {
            // <div class="row">
            const return_certificates = worker_certfications.map((cert, index) =>
                <div className="col-md-6 mb-4" key={index}>
                    <div className="certification__box">
                        <div className="certification__box__pic">
                            {
                                cert.certification_pic_path
                                ?
                                <a
                                    className="example-image-link"
                                    href={cert.certification_pic_path}
                                    data-lightbox="example-set"
                                >
                                    <img
                                        src={cert.certification_pic_path}
                                        alt="certificate"
                                    />
                                </a>
                                :
                                <img src="/static/images/certificate.jpeg" alt="certificate"></img>
                            }
                        </div>
                        <div className="certification__box__content">
                            <h4 className="certification__box__content--title fix-width-text">
                                {cert.title}
                            </h4>
                            <p className="certification__box__content--education mt-2">
                                {cert.organisation}
                            </p>
                        </div>
                    </div>
                </div>
            );

            setWorkerCertificates(return_certificates)
        }
    }, [worker_certfications]);

    if (worker_certfications) {
        if (worker_certfications.length === 0) {
            return (
                <></>
            );
        }
    }
    return (
        <>
            <p>Certifications</p>
            <br/>
            <div className="row">
                {workerCertificates}
            </div>
        </>
    )
}


function WorkerWorkDetail(workerData: WorkerDetailInterface) {
    const {
        profile_pic_path, total_amount, no_show_count
    }
    = workerData;
    // const [imageSrc, setImageSrc] = useState(profile_pic_path ? profile_pic_path : "");
    // const handleImageError = () => {
    //     const error_img = "/static/images/user-default.png";
    //     setImageSrc({error_img}); // Replace with a placeholder image URL
    // }

    const error_img = "/static/images/user-default.png";

    const profile_pic = profile_pic_path ? profile_pic_path : error_img; 

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>             
                  <Grid item xs={12} sm={3} md={3} textAlign={{ sm: 'left' }}>
                    <Box pr={3} pb={2}>
                        <p>Total Amount</p>
                        <div className="image-box" style={{
                            display: "inline-block",
                            // border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "5px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)"
                        }}>
                            $ {total_amount ? total_amount : 0}               
                            <span className="completed--badge ms-2">Completed</span>
                        </div>
                    </Box>

                    <Box pt={2} pr={3} pb={2}>
                        <p>Experience</p>
                        <WorkerExperience {...workerData} />
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} sm={3} md={3} textAlign={{ sm: 'left' }}>
                    <Box pr={3} pb={2}>
                        <p>Payment Method Preference</p>
                        <PaymentMethodPreferences {...workerData} />
                    </Box>

                    <Box pt={2} pr={3} pb={2}>
                        <p>Education</p>
                        <WorkerEducation {...workerData} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} textAlign={{ sm: 'left' }}>
                    <Box pr={3} pb={2}>
                        <p>Skills</p>
                        <WorkerSkills {...workerData} />
                    </Box>

                    <Box pt={2} pr={3} pb={2}>
                        <p>Driving License</p>
                        <WorkerDrivingLicence {...workerData} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} textAlign={{ sm: 'left' }}>
                    <Box pr={3} pb={2}>
                        <p>Age</p>
                        <WorkerAge {...workerData}/>
                    </Box>

                    <Box pt={2} pr={3} pb={2}>
                        <p
                            style={{marginTop: "15px"}}
                        >
                            No Show Count
                        </p>
                        <p className="simple__textdata">
                            {
                                (no_show_count == "0")
                                ?
                                    <span className="uploaded ms-2">
                                        0
                                    </span>
                                :
                                    <span className="not-uploaded ms-2">
                                        {no_show_count}
                                    </span>
                            }
                        </p>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} textAlign={{ sm: 'left' }}>
                    <Box pt={2} pr={3} pb={2}>
                        <WorkerCertificates {...workerData} />
                    </Box>
                  </Grid>

                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
  
  export default WorkerWorkDetail;
  
