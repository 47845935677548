import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Box, Container, Grid } from '@mui/material';
import Footer from 'src/components/Footer';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest, searchPostRequest } from 'src/data/helpers/services';
import { EMPLOYER_LIST_API, SKILL_LIST_API } from 'src/constants';
import {
    employerListContentData,
    employerListContentDataModified
} from 'src/data/mappers/EmployerList';
import { InsertEmployerListContent } from 'src/store/employers';
import EmployerListDataTable from '../pages/Components/employers/EmployersDatatable';

import axios from 'axios';
import SkillListDataTable from '../pages/Components/skills/SkillsDatatable';
import { skillListContentData, skillListContentDataModified } from 'src/data/mappers/SkillList';
import { InsertSkillListContent } from 'src/store/skills';
import SkillAdd from '../pages/Components/skills/SkillAddForm';
import SkillAddForm from '../pages/Components/skills/SkillAddForm';

function SkillList() {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    let skillListContent = useSelector((state: RootState) => state.skills.skillListContent);

    const [skillsCount, setSkillsCount] = useState(0);

    const savedPageNumber = localStorage.getItem('skillPageNumber');

    var currentPageNumber = 0;
    if (savedPageNumber && savedPageNumber !== null) {
        const current_page = Number(savedPageNumber);
        if (current_page >= 0) {
            currentPageNumber = current_page;
        }
    }

    const [controller, setController] = useState({
        // pageNumber: 0,
        pageNumber: currentPageNumber,
        pageLimit: 10
    });

    const [searchTerm, setSearchTerm] = useState('');

    const [loading, setLoading] = useState(false);

    // useRef keeps track of the current cancel token
    const sourceRef = useRef(null);

    const [refreshTable,setRefreshTable] = useState<boolean>(false);

    useEffect(() => {
        getSkillList();
    }, [controller, searchTerm, refreshTable]);

    const getSkillList = async() => {
        setLoading(true);


        // We are creating a new cancel token and saving it to the sourceRef ref before making
        // the API request & passing this cancelToken to axios.
        // By using this if a new search is triggered before the previous one has completed
        // older API requests will be cancelled from promise & result of latest request will
        // be returned
        if (sourceRef.current) {
          sourceRef.current.cancel('Operation canceled by the user.');
        }
  
        const source = axios.CancelToken.source();
        sourceRef.current = source;

        let res:any = {}
        res = await searchPostRequest(
            SKILL_LIST_API,
            {
                page_number: controller.pageNumber,
                page_limit: controller.pageLimit,
                search_text: searchTerm
            } ,
            token,
            source.token,
        );

        if (res) {
            if (isApiCodeSucess(res)) {
                let formattedSkillListContentData = res.data?.data.skills as skillListContentData;
                let formattedSkillListContent: skillListContentDataModified = [];
                formattedSkillListContentData.forEach((x) => {
                    formattedSkillListContent.push({
                        id: x.id,
                        title: x.title,
                        translated_skill: x.translated_skill,
                        is_blocked: x.is_blocked,
                        created_at: x.created_at,
                        extras:{
                            setModeType: setModeType,
                            setOpen: setOpen,
                            setSkillId: setSkillId,
                            setExistingTitle: setExistingTitle,
                            setExistingTitleSpanish: setExistingTitleSpanish,
                        }
                    });
                });
                dispatch(InsertSkillListContent(formattedSkillListContent));
                setSkillsCount(res.data?.data.skills_count);
            }
        }
        setLoading(false);
    }

    const skillDatatableProps = {
      skillListContent: skillListContent,
      skillsCount: skillsCount,
      setSkillsCount: setSkillsCount,
      controller: controller,
      setController: setController,
      searchTerm: searchTerm,
      setSearchTerm: setSearchTerm,
      loading: loading,
      setLoading: setLoading
    }

    const [open, setOpen] = useState<boolean>(false);
    // const [refreshTable,setRefreshTable] = useState<boolean>(false);
    const [modeType,setModeType] = useState<string>("Add");
    const [skillId,setSkillId] = useState<string>("");
    const [existingTitle,setExistingTitle] = useState<string>("");
    const [existingTitleSpanish, setExistingTitleSpanish] = useState<string>("");

    let SkillFormProps = {
        open: open,
        setOpen: setOpen,
        refreshTable: refreshTable,
        setRefreshTable: setRefreshTable,
    }

    let AddSkillProps = {
        ...SkillFormProps,
        modeType: modeType,
        formType: "Skill",
        skillId: skillId,
        existingTitle: existingTitle,
        existingTitleSpanish: existingTitleSpanish
    }

    return (
      <>
          <Box
            className=""
            sx={{
              marginTop: "-90px"
            }}
          >
              {/* <Box className='dashboard__topbar'>
                  <Box component="h2" className="dashboard__topbar--heading">
                      Skills
                  </Box>
              </Box> */}
              <SkillAddForm {...AddSkillProps}/>
              <Box
                className='position--relative'
                mt={2}
              >
                  <Box className='table__actionbuttons'>
                    <button
                        className='saveBtn shrinkbtn'
                        onClick={() => {
                            setModeType("Add")
                            setExistingTitle("")
                            setExistingTitleSpanish("")
                            setOpen(true)
                        }}
                    >
                        Add New
                    </button>
                  </Box>
                  <Box className='dashboard__content'>
                    <SkillListDataTable {...skillDatatableProps} />
                  </Box>
              </Box>
          </Box>
      </>
    );
}

export default SkillList;

