import { Reducer } from 'react';
import {
    WORKER_LIST_CONTENT_DATA
} from '../../constants';

import { workerListActions, workerListState } from './types';

export const initialState: workerListState = {
    workerListContent: [],
};

export const workerListReducer: Reducer<workerListState, workerListActions> = (state = initialState, action) => {
    switch (action.type) {
        case WORKER_LIST_CONTENT_DATA:
            return {
                ...state,
                workerListContent: [...(
                    //@ts-ignore
                    action?.data || [])],
            };
        default:
            return state;
    }
};

