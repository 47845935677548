import {
    DataGrid,
    GridColDef,
    GridRenderCellParams
} from '@mui/x-data-grid';
// @ts-ignore
import ClearIcon from '@mui/icons-material/Clear';
// @ts-ignore
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// @ts-ignore
import TextField from '@mui/material/TextField';
import React,
    {
        useEffect,
        useMemo
} from 'react';
// @ts-ignore
import StarIcon from '@mui/icons-material/Star';
// @ts-ignore
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';

import ButtonGroup from '@mui/material/ButtonGroup';
import { Avatar, debounce, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { DataGridPropsWithDefaultValues } from '@mui/x-data-grid/models/props/DataGridProps';
import { jobWorkerListContentModified } from 'src/data/mappers/JobWorkerList';
// @ts-ignore
import PlaceIcon from '@mui/icons-material/Place';
import ReadMoreReadLess from 'react-read-more-read-less';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
// @ts-ignore
import LineStyleIcon from '@mui/icons-material/LineStyle';


const frozenColumns = [
    { key: 'first_name', name: 'Name' },
];
interface MyDataGridProps extends DataGridPropsWithDefaultValues {
    lockedColumns?: {
      leftColumns?: typeof frozenColumns;
    //   rightColumns?: Column[];
    };
}


function WorkerName(params: any) {
    params = params.params;
    const first_name = params.row.first_name;
    const last_name = params.row.last_name;
    const rating = params.row.rating;
    const profile_pic_path = params.row.profile_pic_path;

    var actual_rating = "0.0";
    if (rating) {
        const num_rating = Number(rating);
        const rounded_rating = num_rating.toFixed(1);
        actual_rating = rounded_rating;
    }
    const worker_name = (
        <>
            {first_name ? first_name : ""} {last_name ? last_name : ""}
        </>
    )

    const worker_email = (
        <>
            <StarIcon sx={{color: "#fdd643", fontSize: "21px"}}/>
            <span
                style={{
                    // whiteSpace: "break-spaces",
                    // wordBreak: "break-all",
                    fontSize: "12px",
                    // color: "rgb(68, 174, 75)",
                    marginLeft: "4px"
                }}
            >
                {actual_rating}
            </span>
        </>
    )


    const worker_profile_pic = (
        <>
            <ListItem>
                <ListItemAvatar
                    sx={{marginRight: "-12px"}}
                >
                    <Avatar alt="User profile picture" src={profile_pic_path} />
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        marginTop: "0px",
                        marginBottom: "0px",
                    }}
                    primary={worker_name}
                    secondary={worker_email}
                />
            </ListItem>
        </>
    )
    return (
        <>
            {worker_profile_pic}
        </>
    ) 
}

function Location(params: any) {
    const user_location = params.params.location;
    var return_location = <div style={{textAlign: "center"}}><span>-</span></div>;
    if (user_location) {
        return_location = <div className='fix-width-data'>
            <PlaceIcon
                sx={{
                    color: "#9e6de6",
                    fontSize: '17px'
                }}
            />
            <span
                style={{
                    "marginLeft": "7px"
                }}
            >
                {user_location}
            </span>
        </div>
    }
    return (
        return_location
    )
}

function WorkerSkills(params: any) {
    const skills = (params.params.skills).join(',  ');
    var return_skills = <div style={{textAlign: "center"}}><span>-</span></div>;
    if (skills) {
        return_skills = <div className='fix-width-data '>
                <LineStyleIcon
                    sx={{
                        color: "#14a899",
                        fontSize: '17px',
                        marginTop: "2px"
                    }}
                />
                <span style={{marginLeft: "7px"}}>
                    <ReadMoreReadLess
                        charLimit={30} // set the number of characters to display before "read more" link
                        readMoreText="read more" // set the text for "read more" link
                        readLessText="...read less" // set the text for "read less" link
                        readMoreClassName="read-more-css"
                        readLessClassName="read-less-css"
                    >
                        {skills}
                    </ReadMoreReadLess>
                </span>
            </div>
    }
    return (
        return_skills
    )
}


function JobStauts(params:any) {
    params = params.params;
    const job_status = params.row.worker_job_status;

    const return_job_status = (
        <>
            <AppRegistrationIcon
                sx={{
                    color: "#9e6de6",
                    fontSize: '17px',
                    marginLeft: '-20px'
                }}
            />
            <span
                style={{
                    "marginLeft": "7px"
                }}
            >
                {job_status}
            </span>
        </>
    )

    return (
        <>
            {return_job_status}
        </>
    )    
}


const columns: GridColDef[] = [
    {
        field: 'id', headerName: 'ID', width: 0, hide: true
    },
    {
        field: 'first_name', headerName: 'Name',
        headerClassName: 'super-app-theme--header sticky-col', type: 'string',
        flex: 0.6, headerAlign:'center', align:'left', filterable: true, minWidth: 150,
        renderCell: (params: GridRenderCellParams<boolean>) => (
            <WorkerName params={params} />
        ),
    },
    {
        field: 'location', headerName: 'Location',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.6,
        headerAlign:'center', align:'left', minWidth: 200,
        renderCell: (params: GridRenderCellParams<string>) => (
            <Location params={params.row} />
        )
    },
    {
        field: 'skills', headerName: 'Skills',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.8,
        headerAlign:'center', align:'center', minWidth: 210,
        renderCell: (params: GridRenderCellParams<object>) => (
            <WorkerSkills params={params.row} />
        )
    },
    {
        field: 'worker_job_status', headerName: 'Worker job status',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.5,
        headerAlign:'center', align:'center', minWidth: 100,
        renderCell: (params: GridRenderCellParams<string>) => (
            <JobStauts params={params} />
        ),
    },
    {
        field: 'Action', headerName: 'Actions',headerClassName: 'super-app-theme--header',
        type: 'string', sortable:false, flex: 0.4, minWidth: 100,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <ButtonGroup size="small" aria-label="small button group">
                    <Link to={`/admin/workers/detail/${params.row.id}/`} style={{ textDecoration: 'none' }}>
                        <VisibilityIcon sx={{ "color": "##0a58ca" }} />
                    </Link>
                </ButtonGroup>
            </>
        )
    },
]

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}


interface controllerInterface {
    pageNumber: number,
    pageLimit: number
}


interface jobWorkerDatatableProps {
    workerListContent: jobWorkerListContentModified;
    workersCount: number,
    setWorkersCount: any,
    controller: controllerInterface,
    setController: any,
    searchTerm: string,
    setSearchTerm: any,
    loading: boolean,
    setLoading: any
}

const JobWorkerListDataTable = (workerDatatableProps: jobWorkerDatatableProps) => {
    let {
        workerListContent,
        workersCount,
        setWorkersCount,
        controller,
        setController,
        searchTerm,
        setSearchTerm,
        loading,
        setLoading
    } = workerDatatableProps;

    const handlePageChange = (newPage: number) => {
        setController({
            ...controller,
            pageNumber: newPage
        });
        localStorage.setItem('jobWorkersPageNumber', String(newPage));
    };

    const handleChangeRowsPerPage = (newPageSize: number) => {
        setController({
            ...controller,
            pageLimit: newPageSize
        });
    };

    // To save search text in state so that on page reload text remains in search text as it is
    useEffect(() => {
        const savedSearchText = localStorage.getItem('JobWorkersSearchText');
        if (savedSearchText !== null) {
            setSearchTerm(savedSearchText);
        }
    }, []);

    const [rows, setRows] = React.useState<jobWorkerListContentModified>(workerListContent);

    const handleSearch = (searchValue: string) => {
        localStorage.setItem('JobWorkersSearchText', searchValue);
        // debouncedSearch(searchValue);
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = workerListContent.filter((row: any) => {
          return Object.keys(row).some((field: any) => {
            return searchRegex.test(row[field]);
          });
        });
        setRows(filteredRows);
    };

    const debouncedSearch = useMemo(() => debounce((searchValue: string) => {
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = workerListContent.filter((row: any) => {
          return Object.keys(row).some((field: any) => {
            return searchRegex.test(row[field]);
          });
        });
        setRows(filteredRows);
    }, 10), []); // Set the debounce delay to 500ms.

    useEffect(() => {
        setRows(workerListContent);
    }, [workerListContent]);

    const lockedColumns = {
        leftColumns: [{ key: 'first_name', name: 'name' }],
      };

    return (
        <>
            {/* server side pagination */}
            
            <div
                className='datagrid__layout sticky-col'
                style={{
                    // height: 750,
                    width: '100%'
                }}
            >
                <DataGrid
                    className='datagrid--custom'
                    components={{  Toolbar: QuickSearchToolbar }}

                    sx={{
                        padding: "0px !important",
                        overflowx: 'scroll'
                    }}
                    autoHeight={true}

                    paginationMode='server'
                    rowCount={workersCount}
                    rowsPerPageOptions={[controller.pageLimit]}
                    pagination
                    onPageChange={(newPage) => handlePageChange(newPage)}
                    onPageSizeChange={(newPageSize) => handleChangeRowsPerPage(newPageSize)}
                    rows={rows}
                    columns={columns}
                    page={controller.pageNumber}
                    pageSize={controller.pageLimit}
                    density={'comfortable'}
                    // onFilterChange={handleFilterChange}
                    loading={loading}
                    // autoPageSize={true}
                    componentsProps={{ 
                        toolbar: {
                            value: searchTerm,
                            onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => handleSearch(event.target.value),
                            clearSearch: () => handleSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
};

export default JobWorkerListDataTable;

