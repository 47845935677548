import { Reducer } from 'react';
import {
    SKILL_LIST_CONTENT_DATA, SKILL_STATUS_UPDATE
} from '../../constants';

import { skillListActions, skillListState } from './types';

export const initialState: skillListState = {
    skillListContent: [],
};

export const skillListReducer: Reducer<skillListState, skillListActions> = (state = initialState, action) => {
    switch (action.type) {
        case SKILL_LIST_CONTENT_DATA:
            return {
                ...state,
                skillListContent: [...(
                    //@ts-ignore
                    action?.data || [])],
            };
        case SKILL_STATUS_UPDATE:
            return {
                ...state,
                skillListContent: state.skillListContent.map(skill => {
                    if (String(skill.id) === String(action.data)) {
                        skill.is_blocked = !skill.is_blocked
                        return {...skill}
                    };
                    return skill;
                }),
                // searchedValue : state.searchedValue
            }
        default:
            return state;
    }
};

