import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  Tooltip,
  Avatar,
  CardMedia,
  Button,
  IconButton,
  Container,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import EmployerList from '.';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { EmployerDetailInterface } from 'src/data/mappers/EmployerDetail';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { EMPLOYER_DETAIL_API, JOB_DETAIL_API } from 'src/constants';
import EmployerDetailCards from '../pages/Components/employers/employerDetailCards';
import { Helmet } from 'react-helmet-async';
import RecentActivity from '../applications/Users/profile/RecentActivity';
// import { Feed } from '@mui/icons-material';
import PopularTags from '../applications/Users/profile/PopularTags';
import MyCards from '../applications/Users/profile/MyCards';
import Addresses from '../applications/Users/profile/Addresses';
import ProfileCover from '../applications/Users/profile/ProfileCover';
import EemployerProfileCover from '../pages/Components/employers/employerProfileCover';
import EmployerPersonalDetailBox from '../pages/Components/employers/employerPersonalDetailBox';
import Feed from '../applications/Users/profile/Feed';
import TabsDemo from '../pages/Components/Tabs';
import EmployerJobTabs from '../pages/Components/employers/employerJobTabs';
import { JobDetailInterface } from 'src/data/mappers/JobDetail';
import JobBasicDetailCard from '../pages/Components/jobs/JobBasicDetailCard';
import JobBasicDetailTabs from '../pages/Components/jobs/JobDetailTabs';

const Input = styled('input')({
  display: 'none'
});

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: -${theme.spacing(9)};
    margin-left: ${theme.spacing(2)};

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const CardCover = styled(Card)(
  ({ theme }) => `
    position: relative;

    .MuiCardMedia-root {
      height: ${theme.spacing(26)};
    }
`
);

const CardCoverAction = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
`
);

const JobDetail = () => {
    const { jobId } = useParams();

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );
    const [jobData, setJobData] = useState<JobDetailInterface>();

    const getJobDetail = async (id: number|undefined) => {
        const response = await postRequest(
            JOB_DETAIL_API,
            { job_id: id },
            token
        );
        if (response) {
            if (isApiCodeSucess(response)) {
                const response_data = response.data?.data;
                const job_data = response_data.job;
                // const trail_subscription = response_data.trail_subscription;
                setJobData(job_data);
            }
        }
    }
    useEffect(() => {
            getJobDetail(parseInt(jobId));
    }, []);

  return (
  <>
      <Container
        sx={{
          mt: -5,
        }}
        maxWidth={false}
        // maxWidth="lg"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          // sx={{width: "100%"}}
        >
          <Grid item xs={11} md={11}>
              <JobBasicDetailCard {...jobData}/>
          </Grid>
          <Grid item xs={12} md={12}>
              <JobBasicDetailTabs {...jobData}/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default JobDetail;

