import { Navigate, useRoutes } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';
import router from 'src/router';
import LoginRoutes from 'src/login_router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { Alert, Box, CssBaseline, LinearProgress, Snackbar } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import './styles/App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, RootStateInterface } from './store';
import { userState } from './store/user';
import { ShowErrorAlert, ShowSuccessAlert } from './store/alerts';

function App() {
  const content = useRoutes(router);
  const alerts = useSelector((state: RootState) => state.alerts);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(ShowSuccessAlert({visible:false, message: ""}));
  }

  const handleErrorClose = () => {
    dispatch(ShowErrorAlert({visible:false, message: ""}));
  }

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {
          alerts.loader.visible
          ?
          <Box
            component={'div'}
            sx={{ width: '100%', position:'fixed', zIndex:"9999" }}
          >
            <LinearProgress
              sx = {{
                backgroundColor: 'var(--brand--font--link)'
              }}
            />
          </Box>
          :
          ""
        }
        <Snackbar
          className='red--whiteIcon'
          anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
          open={alerts.success.visible}
          autoHideDuration={3000}
          onClose={handleClose}
        >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%', color: '#fff', background:  '#5AAA77', fontWeight: 400}}
            >
                {alerts.success.message}
            </Alert>
        </Snackbar>

        <Snackbar
          className='red--whiteIcon'
          anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
          open={alerts.error.visible}
          autoHideDuration={3000}
          onClose={handleErrorClose}
        >
            <Alert
              onClose={handleErrorClose}
              severity="error"
              sx={{ width: '100%', color: '#fff', background: '#EB3751', fontWeight: 400 }}>
                {alerts.error.message}
            </Alert>
        </Snackbar>
        {content}
          {/* <LoginRoutes /> */}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export const PrivateRoute = ({ children, ...rest }: any) => {
  const user: userState = useSelector((state: RootStateInterface) => state.user);

  if (((user.authorization.access_token?.length > 0) || (user.authorization.access_token?.length > 0)) === false) {
      return <Navigate to={{ pathname: "/" }} />
  }

  return children;
}

export default App;
