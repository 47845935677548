// react imports
import { Reducer } from "react";

// local imports
import {
    HIDE_BACKDROP,
    HIDE_LOADER,
    SHOW_BACKDROP,
    SHOW_DIALOG_BOX,
    SHOW_ERROR_ALERT,
    SHOW_LOADER,
    SHOW_SUCCESS_ALERT
} from "../../constants";
import { AlertActions, AlertsInterface } from "./types";


// write your functions here
export const initialState:AlertsInterface = {
    success : {
        visible: false,
        message: ''
    },
    error: {
        visible: false,
        message: ''
    },
    dialog: {
        visible: false,
        title: '',
        message: '',
        closeBtn: ''
    },
    loader: {
        visible: false
    },
    backdrop: {
        visible: false
    }
}


export const alertsReducer: Reducer<AlertsInterface, AlertActions> = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SHOW_SUCCESS_ALERT:
            return {
                // @ts-ignore
                ...state,
                // @ts-ignore
                success: payload.payload
            };
        case SHOW_ERROR_ALERT:
            return {
                ...state,
                // @ts-ignore
                error: payload.payload
            };
        case SHOW_DIALOG_BOX:
            return {
                ...initialState
            };
        case SHOW_LOADER:
            return {
                ...state,
                loader: {visible:true}
            };
        case HIDE_LOADER:
            return {
                ...state,
                loader: {visible:false}
            };
        case SHOW_BACKDROP:
            return {
                ...state,
                backdrop: {visible:true}
            };
        case HIDE_BACKDROP:
            return {
                ...state,
                backdrop: {visible:false}
            };
        default:
            return {
                ...state
            };
    }
};

