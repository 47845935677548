import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlaceIcon from '@mui/icons-material/Place';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import UnsubscribeRoundedIcon from '@mui/icons-material/UnsubscribeRounded';
import PhoneDisabledRoundedIcon from '@mui/icons-material/PhoneDisabledRounded';
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded';
import moment from 'moment';
import { Link } from 'react-router-dom';

// import { categoryListContentDataModified } from '../../../data/mappers/categoryList';
import {
    employerListContentData,
    employerListContentDataModified
} from 'src/data/mappers/EmployerList';

import ButtonGroup from '@mui/material/ButtonGroup';
import { number } from 'prop-types';
import {
    debounce, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, ListItem, ListItemText
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from 'src/store/alerts';
import { isApiCodeSucess, postRequest } from 'src/data/helpers/services';
import { BLOCK_UNBLOCK_USER_API, DELETE_MULTIPLE_USERS_API, DELETE_USER_API } from 'src/constants';
import { InsertEmployerListContent, UpdateEmployerBlockStatus } from 'src/store/employers';


import { makeStyles } from '@material-ui/core/styles';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

import Button from '@mui/material/Button';
import {
    Select,
    MenuItem,
    // Button   // This import is causing UI break
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        margin: theme.spacing(1),
        color: "#223354",
        textDecoration: "none !important",
        '&:hover': {
            backgroundColor: '#ffffff',
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#3c52b2'
        },
        marginBottom: "-35px"
    },
    select: {
        minWidth: 200,
    },
}));

// const useStyles = makeStyles((theme) => ({
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 120,
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(2),
//     },
//   }));

function EmployerName(params: any) {
    params = params.params;
    const employer_name = params.row.employer_name;
    const email = params.row.email;

    const employer_return_name = (
        <>
            <p className='fix-width-employer-name'>
                {employer_name ? employer_name : "-"}
            </p>
        </>
    )

    const employer_email = (
        <>
            <div
                className='fix-width-data'
            >
                <MailOutlineIcon
                    style={{
                        fontSize: "14px",
                        marginTop: "4px"
                    }}
                    sx={{ color: "rgb(68, 174, 75)" }}
                />
                <span
                    style={{
                        fontSize: "12px",
                        // color: "rgb(68, 174, 75)",
                        marginLeft: "2px"
                    }}
                >
                    {email}
                </span>
            </div>
        </>
    )


    const employer_full_name = (
        <>
            <ListItem>
                <ListItemText
                    sx={{
                        marginTop: "0px",
                        marginBottom: "0px"
                    }}
                    primary={employer_return_name}
                    secondary={employer_email}
                />
            </ListItem>
        </>
    )
    return (
        <>
            {employer_full_name}
        </>
    )
}


function DeleteButton(params: any) {
    const controller = params.extras.controller;
    const page_number = controller ? controller.pageNumber : 0;
    const page_limit = controller ? controller.pageLimit : 10;
    const search_text = params.extras ? params.extras.searchTerm : "";
    const setEmployersCount = params.extras.setEmployersCount;

    const [open, setOpen] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)

    const [dialogTitle, setDialogTitle] = React.useState("User Delete Confirmation");
    const [dialogContext, setDialogContext] = React.useState(
        <>
            This action will permanently delete user <b>{params.employer_name}</b>
        </>
    );

    let dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    const deleteConfirmed = async () => {
        dispatch(ShowProgressBar());
        setInProgress(true);
        // setLoading(true);
        const res: any = await postRequest(
            DELETE_USER_API,
            {
                user_id: params.user_id,
                page_number: page_number,
                page_limit: page_limit,
                search_text: search_text,
                confirmation: deleteConfirmation
            },
            token
        );

        dispatch(HideProgressBar());
        if (res) {
            if (isApiCodeSucess(res)) {
                const confirm_required = res.data.data.confirm_required;
                if (confirm_required) {
                    dispatch(ShowProgressBar());
                    setInProgress(false);
                    setOpen(true);
                    // setDialogTitle(
                    //     `User Delete Action Required for ${params.first_name} ${params.last_name}`
                    // );
                    setDialogTitle(
                        `User Delete Action Required for ${params.employer_name}`
                    );
                    setDialogContext(res.data.message);
                    setDeleteConfirmation(true);
                }
                else {
                    setDeleteConfirmation(false);
                    dispatch(ShowSuccessAlert({ visible: true, message: res.data.message }));
                    // dispatch(UpdateEmployerDeleteStatus(Number(params.id)));
                    const users = res.data?.data.users;
                    const users_count = res.data?.data.users_count;
                    let formattedEmployerListContentData = users as employerListContentData;
                    let formattedEmployerListContent: employerListContentDataModified = [];
                    formattedEmployerListContentData.forEach((x) => {
                        formattedEmployerListContent.push({
                            id: x.id,
                            employer_name: x.employer_name,
                            email: x.email,
                            phone_no: x.phone_no,
                            country_name: x.country_name,
                            location: x.location,
                            is_email_verified: x.is_email_verified,
                            is_phone_verified: x.is_phone_verified,
                            is_profile_created: x.is_profile_created,
                            created_at: x.created_at,
                            user_id: x.user_id,
                            is_blocked: x.is_blocked,
                            subscription: x.subscription,
                            extras: {
                                controller: controller,
                                searchTerm: search_text
                            }
                        });
                    });
                    dispatch(InsertEmployerListContent(formattedEmployerListContent));
                    setEmployersCount(users_count);
                }
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
        setInProgress(false);
        setDeleteConfirmation(false);
        dispatch(HideProgressBar());
    };

    return (
        <div>
            <button
                style={{
                    border: 'none',
                    background: 'none',
                    marginTop: '-10px'
                }}
                // className="position-relative mx-2 each--tooltip btn--statuscheck btn--unblock"
                onClick={handleClickOpen}
            >
                <DeleteForeverIcon sx={{ "color": "#da195f" }} />
            </button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {/* <h4>{"User Delete Confirmation"}</h4> */}
                    <span>{dialogTitle}</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContext}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        inProgress
                            ?
                            <span>
                                <h4>Deletion in Progress...</h4>
                            </span>
                            :
                            <>
                                <button className='deleteButton normal--btn cancel-class' onClick={handleClose}>
                                    Cancel
                                </button>

                                <button className='deleteButton normal--btn deleted-class' onClick={deleteConfirmed}>
                                    Delete
                                </button>
                            </>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}


function BlockUnblockButton(params: any) {
    let dispatch = useDispatch();
    const [isBlocked, setIsBlocked] = useState(params.is_blocked);

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    const handleClick = async () => {
        dispatch(ShowProgressBar())
        const res: any = await postRequest(
            BLOCK_UNBLOCK_USER_API,
            {
                user_id: params.user_id
            },
            token
        );

        if (res) {
            dispatch(HideProgressBar())
            if (isApiCodeSucess(res)) {
                dispatch(ShowSuccessAlert({ visible: true, message: res.data.message }));
                const block_status = res.data.data.block_status;
                const updated_block_status = (block_status === 1) ? false : true;
                setIsBlocked(updated_block_status);
                // dispatch(UpdateEmployerBlockStatus(params.row.id));
                dispatch(UpdateEmployerBlockStatus(params.id));
            }
        }
    }
    return (
        <>
            {
                isBlocked
                    ?
                    <button
                        className="position-relative mx-2 each--tooltip btn--statuscheck btn--unblock"
                        onClick={() => { handleClick() }}
                    >
                        {/* <LockOpenOutlinedIcon /> */}
                        UnBlock
                    </button>
                    :
                    <button
                        className="position-relative mx-2 each--tooltip btn--statuscheck btn--block"
                        onClick={() => { handleClick() }}
                    >
                        {/* <LockOutlinedIcon /> */}
                        Block
                    </button>
            }
        </>
    );
}


function Location(params: any) {
    const user_location = params.params.location;
    var return_location = <div style={{ textAlign: "center" }}><span>-</span></div>;
    if (user_location) {
        return_location = <div className='fix-width-data'>
            <PlaceIcon
                sx={{
                    color: "#9e6de6",
                    fontSize: '17px'
                }}
            />
            <span
                style={{
                    "marginLeft": "7px"
                }}>
                {user_location}
            </span>
        </div>
    }
    return (
        return_location
    )
}


function EmailPhoneStauts(params: any) {
    params = params.params;
    const is_email_verified = params.row.is_email_verified;
    const is_phone_verified = params.row.is_phone_verified;

    const email = (
        <>
            {
                is_email_verified ?
                    <MarkEmailReadRoundedIcon
                        style={{
                            fontSize: "20px"
                        }}
                        sx={{
                            color: "rgb(68, 174, 75)"
                        }}
                    />
                    :
                    <UnsubscribeRoundedIcon
                        style={{
                            fontSize: "20px"
                        }}
                        sx={{
                            color: "#da195f"
                        }}
                    />
            }
        </>
    )

    const phone = (
        <>
            {
                is_phone_verified ?
                    <PhoneEnabledRoundedIcon
                        style={{
                            fontSize: "20px"
                        }}
                        sx={{
                            color: "rgb(68, 174, 75)",
                            marginLeft: "10px"
                        }}
                    />
                    :
                    <PhoneDisabledRoundedIcon
                        style={{
                            fontSize: "20px"
                        }} sx={{
                            color: "#da195f", marginLeft: "10px"
                        }}
                    />
            }
        </>
    )

    return (
        <>
            <div>
                <div>{email}{phone}</div>
            </div>
        </>
    )
}


const columns: GridColDef[] = [
    {
        field: 'id', headerName: 'ID', width: 0, hide: true
    },
    {
        field: 'employer_name', headerName: 'Name',
        headerClassName: 'super-app-theme--header', type: 'string', minWidth: 250,
        flex: 0.7, headerAlign: 'center', align: 'left', filterable: true,
        sortable: true, sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams<boolean>) => (
            <EmployerName params={params} />
        ),
    },
    {
        field: 'phone_no', headerName: 'Phone No',
        // headerClassName: 'super-app-theme--header', type: 'number', minWidth: 150,
        headerClassName: 'super-app-theme--header', type: 'string', minWidth: 150,
        flex: 0.5, headerAlign: 'center', align: 'center',
        sortable: true, sortingOrder: ['desc', 'asc'],
    },
    {
        field: 'country_name', headerName: 'Country',
        headerClassName: 'super-app-theme--header', type: 'number', minWidth: 180,
        flex: 0.6, headerAlign: 'center', align: 'center',
        sortable: true, sortingOrder: ['desc', 'asc'],
    },
    {
        field: 'location', headerName: 'Location',
        headerClassName: 'super-app-theme--header', type: 'number', minWidth: 250,
        flex: 0.6, headerAlign: 'center', align: 'left',
        sortable: true, sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams<string>) => (
            <Location params={params.row} />
        )
    },
    {
        field: 'subscription', headerName: 'Subscription',
        headerClassName: 'super-app-theme--header', type: 'number', minWidth: 150, flex: 0.5,
        headerAlign: 'center', align: 'center', hideSortIcons: true, sortable: false,
    },
    {
        field: 'is_email_verified', headerName: 'Verified',
        headerClassName: 'super-app-theme--header', type: 'number', minWidth: 120,
        flex: 0.5, headerAlign: 'center', align: 'center',
        sortable: true, sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams<boolean>) => (
            <EmailPhoneStauts params={params} />
        ),
    },
    {
        field: 'is_profile_created', headerName: 'Profile',
        headerClassName: 'super-app-theme--header', type: 'number', minWidth: 120,
        flex: 0.4, headerAlign: 'center', align: 'center',
        sortable: true, sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams<boolean>) => (
            params.row.is_profile_created ?
                <span style={{ "color": "rgb(68, 174, 75)" }}>Completed</span>
                :
                <span style={{ "color": "#dc120f" }}>Pending</span>
        ),
    },
    {
        field: 'created_at', headerName: 'Created At',
        headerClassName: 'super-app-theme--header', type: 'number', minWidth: 180,
        flex: 0.6, headerAlign: 'center', align: 'center',
        sortable: true, sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.created_at).format('DD/MM/YYYY')}</label>
            </>
        )
    },
    {
        field: 'Action', headerName: 'Action', headerClassName: 'super-app-theme--header',
        type: 'string', sortable: false, flex: 0.6, headerAlign: 'left', minWidth: 200,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <ButtonGroup size="small" aria-label="small button group">
                    <Link
                        to={`/admin/employers/detail/${params.row.id}/`}
                        style={{ textDecoration: 'none' }}
                    >
                        <VisibilityIcon sx={{ "color": "##0a58ca" }} />
                    </Link>

                    <DeleteButton {...params.row} />

                    <BlockUnblockButton {...params.row} />
                </ButtonGroup>
            </>
        )
    },
]


interface controllerInterface {
    pageNumber: number,
    pageLimit: number
}


interface employerListContentDataTableProps {
    employerListContent: employerListContentDataModified;
    employersCount: number,
    setEmployersCount: any,
    controller: controllerInterface,
    setController: any,
    searchTerm: string,
    setSearchTerm: any,
    loading: boolean,
    setLoading: any,
    sortField: string,
    setSortField: any,
    sortOrder: string,
    setSortOrder: any,
    isRowSelected: any,
    setIsRowSelected: any,
    isDesktop: boolean
}


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}


interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
    selectionModel: any;
    setEmployersCount: any;
    controller: any;
    searchTerm: any;
    sortField: any;
    sortOrder: any;
    isRowSelected: any;
    setIsRowSelected: any;
}


function QuickSearchToolbar(
    props: QuickSearchToolbarProps
    // props
) {
    const selectionModel = props.selectionModel;
    const setEmployersCount = props.setEmployersCount;
    const controller = props.controller;
    const searchTerm = props.searchTerm;
    const sortField = props.sortField;
    const sortOrder = props.sortOrder;
    const setIsRowSelected = props.setIsRowSelected;
    const isRowSelected = props.isRowSelected;
    return (
        <>
            <Box
                sx={{
                    p: 0,
                    pb: 1,
                }}
            >
                <TextField
                    className="searchvalue"
                    value={props.value}
                    onChange={props.onChange}
                    placeholder="Search…"
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" sx={{ mr: 2 }} />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                onClick={props.clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        width: {
                            xs: 1,
                            sm: 'auto',
                        },
                        m: (theme) => theme.spacing(1, 0.5, 1.5),
                        '& .MuiSvgIcon-root': {
                            mr: 0,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                    }}
                />


                {/* <div
                    style={{
                        float: "right",
                        marginRight: "10px"
                    }}
                >
                    <select 
                        // value={selectedValue}
                        onChange={handleSelectChange}
                        className='dropdown-button'
                    >
                        <option value="">Select User Action</option>
                        <option value="delete">Delete Selcted Users</option>
                        <option value="block">Block Selected Users</option>
                    </select>
                </div> */}

                <BlockDeleteUsers
                    selectionModel={selectionModel}
                    setEmployersCount={setEmployersCount}
                    controller={controller}
                    searchTerm={searchTerm}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    isRowSelected={isRowSelected}
                    setIsRowSelected={setIsRowSelected}
                />
            </Box>
        </>
    );
}


function BlockDeleteUsers(props) {
    const selectedRows = props.selectionModel;
    const setEmployersCount = props.setEmployersCount;

    const isRowSelected = props.isRowSelected;
    const setIsRowSelected = props.setIsRowSelected;

    const controller = props.controller;
    const page_number = controller ? controller.pageNumber : 0;
    const page_limit = controller ? controller.pageLimit : 10;

    const search_text = props.searchTerm;
    const sort_field = props.sortField;
    const sort_order = props.sortOrder;
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState('User Action');
    const [selectOpen, setSelectOpen] = useState(false);
    const [action, setAction] = useState("");

    let dispatch = useDispatch();

    function handleSelectChange(event) {
        if (!selectedRows) {
            setOpen(false);
            setInProgress(false);
        }
        else {
            setSelectedValue(event.target.value);
            const user_action = event.target.value;
            setAction(user_action);
            setOpen(true);
        }
    }

    function handleSelectOpen() {
        if (selectedRows.length == 0) {
            setIsRowSelected("no");

            setTimeout(() => {
                dispatch(ShowErrorAlert({
                    visible: true,
                    message: "Select user rows to perform any action."
                }));
            }, 500);
        }
        else {
            setIsRowSelected("");

            setSelectOpen(true);
        }
    }

    function handleSelectClose() {
        setSelectOpen(false);
    }

    const [open, setOpen] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("User action confirmation");
    const [dialogContext, setDialogContext] = React.useState(
        <>
            This action will permanently <b>{action}</b> users
        </>
    );

    useEffect(() => {
        if (action === "delete") {
            setDialogTitle("Users delete confirmation");
            setDialogContext(
                <>
                    This action will permanently <b>delete</b> users
                </>
            )
            // setOpen(true)
        }
        else if (action === "block") {
            setDialogTitle("Users block confirmation")
            setDialogContext(
                <>
                    This action will permanently <b>block</b> users
                </>
            )
        }
    }, [action]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    let token = useSelector(
        (state: RootState) => state.user.authorization.access_token
    );

    const deleteConfirmed = async () => {
        dispatch(ShowProgressBar());
        setInProgress(true);
        // setLoading(true);
        const res: any = await postRequest(
            DELETE_MULTIPLE_USERS_API,
            {
                user_id_list: selectedRows,
                confirmation: true,
                action: action,
                user_type: "employer",
                page_number: page_number,
                page_limit: page_limit,
                search_text: search_text,
                sort_field: sort_field,
                sort_order: sort_order
            },
            token
        );

        dispatch(HideProgressBar());
        console.log("res", res)
        if (res) {
            if (isApiCodeSucess(res)) {
                dispatch(ShowSuccessAlert({ visible: true, message: res.data.message }));
                // dispatch(UpdateEmployerDeleteStatus(Number(params.id)));
                const users = res.data?.data.users;
                const users_count = res.data?.data.users_count;
                let formattedEmployerListContentData = users as employerListContentData;
                let formattedEmployerListContent: employerListContentDataModified = [];
                formattedEmployerListContentData.forEach((x) => {
                    formattedEmployerListContent.push({
                        id: x.id,
                        employer_name: x.employer_name,
                        email: x.email,
                        phone_no: x.phone_no,
                        country_name: x.country_name,
                        location: x.location,
                        is_email_verified: x.is_email_verified,
                        is_phone_verified: x.is_phone_verified,
                        is_profile_created: x.is_profile_created,
                        created_at: x.created_at,
                        user_id: x.user_id,
                        is_blocked: x.is_blocked,
                        subscription: x.subscription,
                        extras: {
                            controller: controller,
                            searchTerm: search_text
                        }
                    });
                });
                dispatch(InsertEmployerListContent([]));
                dispatch(InsertEmployerListContent(formattedEmployerListContent));
                setEmployersCount(users_count);
                setOpen(false);
                setInProgress(false);
                setSelectedValue('User Action');
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
        setInProgress(false);
        dispatch(HideProgressBar());
        setSelectOpen(false);
        setSelectedValue('User Action');
    };

    return (
        <div
            style={{
                float: "right",
                marginRight: "10px"
            }}
        >
            <Button className={classes.button} onClick={handleSelectOpen}>
                {selectedValue}
            </Button>
            <Select
                value={selectedValue}
                onChange={handleSelectChange}
                onClose={handleSelectClose}
                onOpen={handleSelectOpen}
                open={selectOpen}
                className={classes.select}
            >
                <MenuItem value="User Action"></MenuItem>
                <MenuItem value="delete">Delete</MenuItem>
                <MenuItem value="block">Block</MenuItem>
            </Select>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {/* <h4>{"User Delete Confirmation"}</h4> */}
                    <span>{dialogTitle}</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContext}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        inProgress
                            ?
                            <span>
                                <h4>Action in Progress...</h4>
                            </span>
                            :
                            <>
                                <button className='deleteButton normal--btn cancel-class' onClick={handleClose}>
                                    Cancel
                                </button>

                                <button className='deleteButton normal--btn deleted-class' onClick={deleteConfirmed}>
                                    {action ? action : "Confirm"}
                                </button>
                            </>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}


const EmployerListDataTable = (employerDatatableProps: employerListContentDataTableProps) => {
    let {
        employerListContent,
        employersCount,
        setEmployersCount,
        controller,
        setController,
        searchTerm,
        setSearchTerm,
        loading,
        setLoading,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        isRowSelected,
        setIsRowSelected,
        isDesktop
    } = employerDatatableProps;

    const handlePageChange = (newPage: number) => {
        setController({
            ...controller,
            pageNumber: newPage
        });
        localStorage.setItem('employerPageNumber', String(newPage));
    };

    // To save search text in state so that on page reload text remains in search text as it is
    useEffect(() => {
        const savedSearchText = localStorage.getItem('employerSearchText');
        if (savedSearchText !== null) {
            setSearchTerm(savedSearchText);
        }
    }, []);

    const handleChangeRowsPerPage = (newPageSize: number) => {
        setController({
            ...controller,
            pageLimit: newPageSize
        });
    };

    const [rows, setRows] = React.useState<employerListContentDataModified>(employerListContent);

    const handleSearch = (searchValue: string) => {
        localStorage.setItem('employerSearchText', searchValue);
        // debouncedSearch(searchValue);
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = employerListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    };

    const handleSortModelChange = (newModel) => {
        // `newModel` represents the updated sorting model
        const { field, sort } = newModel[0];
        setSortField(field)
        const direction = sort === 'asc' ? 'asc' : 'desc';
        setSortOrder(direction)
    }

    const debouncedSearch = useMemo(() => debounce((searchValue: string) => {
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = employerListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    }, 10), []); // Set the debounce delay to 500ms.

    useEffect(() => {
        setRows(employerListContent);
    }, [employerListContent]);


    const [selectionModel, setSelectionModel] = React.useState([]);

    const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
    };

    return (
        <>
            {/* <div className='datagrid__layout sticky-col' */}
            <div className={`datagrid__layout ${isDesktop ? "sticky--col" : ""}`}
                style={{
                    // height: 750,
                    width: '100%'
                }}
            >
                <DataGrid
                    // className='datagrid--custom sticky--columns'
                    className={`datagrid--custom ${isDesktop ? "sticky--columns" : ""}`}

                    components={{ Toolbar: QuickSearchToolbar }}

                    sx={{
                        // overflowx: 'scroll'
                        minHeight: "100vh"
                    }}
                    // stickyHeader
                    // stickyLeft={2}
                    // autoHeight={true}

                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}

                    paginationMode='server'
                    rowCount={employersCount}
                    rowsPerPageOptions={[controller.pageLimit]}
                    pagination
                    onPageChange={(newPage) => handlePageChange(newPage)}
                    onPageSizeChange={(newPageSize) => handleChangeRowsPerPage(newPageSize)}
                    rows={rows}
                    columns={columns}
                    page={controller.pageNumber}
                    pageSize={controller.pageLimit}
                    density={'comfortable'}
                    loading={loading}

                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelectionModelChange}
                    disableSelectionOnClick

                    // autoPageSize={true}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            selectionModel: selectionModel,
                            setEmployersCount: setEmployersCount,
                            controller: controller,
                            searchTerm: searchTerm,
                            sortField: sortField,
                            sortOrder: sortOrder,
                            isRowSelected: isRowSelected,
                            setIsRowSelected: setIsRowSelected,
                            onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => handleSearch(event.target.value),
                            clearSearch: () => handleSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
};

export default EmployerListDataTable;

