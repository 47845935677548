import React from 'react'
import ErrorLabel from './ErrorLabel';

export function TextField(props) {
    const {input, id, name, type, placeholder, value, styleName, ...rest} = props;
    let touched = false;
    let error = false;
    if(rest.meta) {
        touched = rest.meta.touched;
        error = rest.meta.error;
    }

    return (
        <>
            <input id={id} {...input} name={name} type={type} placeholder={placeholder} className={`input--pill ${styleName}`} {...rest} />
            {touched &&
                ((error && <ErrorLabel styleName='text--error'>
                    {error}
                </ErrorLabel>))
            }
        </>
    )
}

export function TextArea(props) {
    const {input, id, name, placeholder, row, styleName, children, ...rest} = props;
    let touched = false;
    let error = false;
    if(rest.meta) {
        touched = rest.meta.touched;
        error = rest.meta.error;
    }

    return (
      <>
        <textarea {...input} id={id} name={name} placeholder={placeholder} rows={row} className={`input--pill ${styleName}`} {...rest}>
            {children}
        </textarea>
        {touched &&
                ((error && <ErrorLabel styleName='text--error'>
                    {error}
                </ErrorLabel>))}
      </>
    )
}


export function PasswordField(props) {
    const {input, id, name, type, placeholder, value, styleName, ...rest} = props;
    let touched = false;
    let error = false;
    if(rest.meta) {
        touched = rest.meta.touched;
        error = rest.meta.error;
    }

    return (
        <>
            <input id={id} {...input} name={name} type={'password'} placeholder={placeholder} className={`input--pill ${styleName}`} {...rest} />
            {touched &&
                ((error && <ErrorLabel styleName='text--error'>
                    {error}
                </ErrorLabel>))
            }
        </>
    )
}
