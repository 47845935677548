import { Box, Container, Grid } from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'src/store';
import { isApiCodeSucess, postRequest, searchPostRequest } from 'src/data/helpers/services';
import { EMPLOYER_JOB_LIST_API } from 'src/constants';
import {
  employerListContentData,
  employerListContentDataModified
} from 'src/data/mappers/EmployerList';

import axios from 'axios';
import JobListDataTable from './jobListDataTable';
import { employerJobListContentData, employerJobListContentDataModified } from 'src/data/mappers/EmployerJobList';
import useResponsive from 'src/hooks/useResponsive';

function JobList(employerJobsListProps: any) {
  const {
    employerId, isDetailPageLoaded, setIsDetailPageLoaded,
    jobType, total_jobs_count, total_jobs
  } = employerJobsListProps;

  const dispatch = useDispatch();
  let navigate = useNavigate();

  let token = useSelector(
    (state: RootState) => state.user.authorization.access_token
  );

  // let employerListContent = useSelector((state: RootState) => state.employers.employerListContent);

  const savedPageNumber = localStorage.getItem('employerJobsPageNumber');

  var currentPageNumber = 0;
  if (savedPageNumber && savedPageNumber !== null) {
    const current_page = Number(savedPageNumber);
    if (current_page >= 0) {
      currentPageNumber = current_page;
    }
  }

  const [controller, setController] = useState({
    // pageNumber: 0,
    pageNumber: currentPageNumber,
    pageLimit: 10
  });

  const [searchTerm, setSearchTerm] = useState('');

  const initialEmployerJobListContentData = [{
    id: "",
    title: "",
    location: "",
    category: [{
      id: "",
      title: ""
    }],
    total_workers: "",
    wage_amount: "",
    is_active: "",
    is_blocked: "",
    job_status: "",
    created_at: ""
  }];

  const [loading, setLoading] = useState(false);
  const [jobsCount, setJobsCount] = useState(0);
  // const [employerJobListContent, setEmployerJobListContent] = useState<employerJobListContentData>(
  //     initialEmployerJobListContentData
  // );
  const [employerJobListContent, setEmployerJobListContent] = useState<employerJobListContentData>(
    []
  );

  // if (isDetailPageLoaded) {
  //     console.log("updating>>>>>>>>>>>>>>>>",total_jobs)
  //     // setLoading(true);
  //     // setJobsCount(parseInt(total_jobs_count));
  //     // setJobsCount(1);
  //     // if (total_jobs) {
  //     //   setEmployerJobListContent(total_jobs);
  //     // }
  //     setIsDetailPageLoaded(false);
  // }

  // useRef keeps track of the current cancel token
  const sourceRef = useRef(null);

  useEffect(() => {
    // if (!isDetailPageLoaded) {
    //   getEmployerJobList();
    // }
    getEmployerJobList();
    // setEmployerJobListContent(employerData)
    setIsDetailPageLoaded(false);
  }, [controller, searchTerm]);

  const getEmployerJobList = async () => {
    setLoading(true);


    // We are creating a new cancel token and saving it to the sourceRef ref before making
    // the API request & passing this cancelToken to axios.
    // By using this if a new search is triggered before the previous one has completed
    // older API requests will be cancelled from promise & result of latest request will
    // be returned
    if (sourceRef.current) {
      sourceRef.current.cancel('Operation canceled by the user.');
    }

    const source = axios.CancelToken.source();
    sourceRef.current = source;

    let res: any = {}
    res = await searchPostRequest(
      EMPLOYER_JOB_LIST_API,
      {
        employer_id: employerId,
        job_type: jobType,
        page_number: controller.pageNumber,
        page_limit: controller.pageLimit,
        search_text: searchTerm
      },
      token,
      source.token,
    );
    if (res) {
      if (isApiCodeSucess(res)) {
        let formattedEmployerJobListContentData = res.data?.data.jobs as employerJobListContentData;
        let formattedEmployerJobListContent: employerJobListContentDataModified = [];
        formattedEmployerJobListContentData.forEach((x) => {
          formattedEmployerJobListContent.push({
            id: x.id,
            title: x.title,
            location: x.location,
            category: x.category,
            total_workers: x.total_workers,
            wage_amount: x.wage_amount,
            is_active: x.is_active,
            is_blocked: x.is_blocked,
            job_status: x.job_status,
            created_at: x.created_at
          });
        });
        setEmployerJobListContent(formattedEmployerJobListContent);
        // dispatch(InsertEmployerListContent(formattedEmployerListContent));
        setJobsCount(res.data?.data.jobs_count);
      }
    }
    setLoading(false);
  }

  const isDesktop = useResponsive('up', 'lg', '');


  const employerDatatableProps = {
    employerJobListContent: employerJobListContent,
    jobsCount: jobsCount,
    setJobsCount: setJobsCount,
    controller: controller,
    setController: setController,
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    loading: loading,
    setLoading: setLoading,
    isDesktop: isDesktop,
  }

  return (
    <>
      <Box
        sx={{
          width: "100%"
        }}
      >
        <Box className='position--relative'>
          <Box
          // className='dashboard__content'
          >
            <JobListDataTable {...employerDatatableProps} />
          </Box>
        </Box>
      </Box>
      {/* <Helmet>
          <title>Quickwork: Employers</title>
        </Helmet>
        <PageTitleWrapper>
          <PageHeader title={"Dashboard"}/>
        </PageTitleWrapper>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12}>
              <Box className="">
                  <Box className='dashboard__topbar'>
                      <Box component="h2" className="dashboard__topbar--heading">
                          Employers
                      </Box>
                  </Box>
                  <Box className='position--relative'>
                      <Box className='dashboard__content'>
                        <EmployerListDataTable employerListContent={employerListContent}/>
                      </Box>
                  </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Footer /> */}
    </>
  );
}

export default JobList;

