export type spamTextListContentDataModified = spamTextListContentDataModifiedObj[]

export interface spamTextListContentDataModifiedObj {
    id: string;
    spam_phrase: string;
    created_at: string;
    extras:any;
}

export type spamTextListContentData = spamTextListContentObj[]

export interface spamTextListContentObj {
    id: string;
    spam_phrase: string;
    created_at: string;
    extras:any;
}


export const newSpamText = (data:any, spamTextId:string) => {
    let spamTextData:any= {
        spam_phrase: (data.spam_phrase.trim())
    }
    if (spamTextId.length > 0) {
        spamTextData.spam_phrase_id = spamTextId
    }
  
    return spamTextData;
}

