import { DataGrid, GridRowsProp, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import PlaceIcon from '@mui/icons-material/Place';
import moment from 'moment';
import { Link } from 'react-router-dom';

// import { categoryListContentDataModified } from '../../../data/mappers/categoryList';
import { employerListContentDataModified } from 'src/data/mappers/EmployerList';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { number } from 'prop-types';
import { debounce } from '@mui/material';
import { employerJobListContentData, employerJobListContentDataModified } from 'src/data/mappers/EmployerJobList';


function ListedStauts(params: any) {
    params = params.params;
    const is_active = params.row.is_active;

    const is_listed = (
        <>
            {
                is_active ?
                    <span style={{ "color": "rgb(68, 174, 75)", "fontWeight": "800" }}>Yes</span>
                    :
                    <span style={{ "color": "#dc120f", "fontWeight": "800" }}>No</span>
            }
        </>
    )

    return (
        <>
            {is_listed}
        </>
    )
}

function JobStauts(params: any) {
    params = params.params;
    const job_status = params.row.job_status;

    const return_job_status = (
        <>
            {
                (job_status == "2") ?
                    <span style={{ "color": "rgb(66, 165, 245)", "fontWeight": "800" }}>Upcoming</span>
                    :
                    (
                        (job_status == "3") ?
                            <span style={{ "color": "#fdd643", "fontWeight": "800" }}>Ongoing</span>
                            :
                            (
                                (job_status == "4") ?
                                    <span style={{ "color": "rgb(68, 174, 75)", "fontWeight": "800" }}>Ended</span>
                                    :
                                    <span style={{ "color": "var(--color-warning)", "fontWeight": "800" }}>Cancelled</span>
                            )
                    )
            }
        </>
    )

    return (
        <>
            {return_job_status}
        </>
    )
}

function Location(params: any) {
    const user_location = params.params.location;
    var return_location = <div style={{ textAlign: "center" }}><span>-</span></div>;
    if (user_location) {
        return_location = <div className='fix-width-data'>
            <PlaceIcon
                sx={{
                    color: "#9e6de6",
                    fontSize: '17px'
                }}
            />
            <span
                style={{
                    "marginLeft": "7px"
                }}>
                {user_location}
            </span>
        </div>
    }
    return (
        return_location
    )
}


const columns: GridColDef[] = [
    {
        field: 'id', headerName: 'ID', width: 0, hide: true
    },
    {
        field: 'title', headerName: 'Title',
        headerClassName: 'super-app-theme--header', type: 'string', minWidth: 200,
        flex: 0.6, headerAlign: 'left', align: 'left', filterable: true,
        renderCell: (params: GridRenderCellParams<string>) => (
            <>
                <ViewQuiltRoundedIcon
                    sx={{
                        color: "#e66d8b",
                        fontSize: '17px'
                    }}
                />
                <span style={{ "marginLeft": "7px" }}>
                    {params.row.title}
                </span>
            </>
        )
    },
    {
        field: 'location', headerName: 'Location',
        headerClassName: 'super-app-theme--header', type: 'number',
        flex: 0.65, headerAlign: 'center', align: 'center', minWidth: 240,
        renderCell: (params: GridRenderCellParams<string>) => (
            <Location params={params.row} />
        )
    },
    {
        field: 'category', headerName: 'Category',
        headerClassName: 'super-app-theme--header', type: 'number',
        flex: 0.45, headerAlign: 'center', align: 'center', minWidth: 110,
        renderCell: (params: GridRenderCellParams<string>) => (
            params.row.category ?
                // <span style={{"color": "rgb(68, 174, 75)"}}>
                //     {params.row.category[0].title}
                // </span>
                <>
                    <CategoryIcon
                        sx={{
                            color: "#057679a3",
                            fontSize: '17px'
                        }}
                    />
                    <span style={{ "marginLeft": "7px" }}>
                        {params.row.category[0].title}
                    </span>
                </>
                :
                <span>-</span>
        ),
    },
    {
        field: 'total_workers', headerName: 'Workers',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.4,
        headerAlign: 'center', align: 'center', minWidth: 120,
        renderCell: (params: GridRenderCellParams<string>) => (
            <>
                <PeopleOutlineIcon
                    sx={{
                        color: "#963ba4",
                        fontSize: '17px'
                    }}
                />
                <span style={{ "marginLeft": "5px" }}>
                    {params.row.total_workers}
                </span>
            </>
        )
    },
    {
        field: 'wage_amount', headerName: 'Wage',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.4,
        headerAlign: 'center', align: 'center', minWidth: 100,
        renderCell: (params: GridRenderCellParams<string>) => (
            <>
                <span style={{ "marginLeft": "5px" }}>
                    $ {params.row.wage_amount}
                </span>
            </>
        )
    },
    {
        field: 'is_active', headerName: 'Listed',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.4,
        headerAlign: 'center', align: 'center', minWidth: 100,
        renderCell: (params: GridRenderCellParams<boolean>) => (
            <ListedStauts params={params} />
        ),
    },
    {
        field: 'is_blocked', headerName: 'Blocked',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.4,
        headerAlign: 'center', align: 'center', minWidth: 100,
        renderCell: (params: GridRenderCellParams<boolean>) => (
            params.row.is_blocked ?
                <span style={{ "color": "#dc120f", "fontWeight": "800" }}>Yes</span>
                :
                <span style={{ "color": "rgb(68, 174, 75)", "fontWeight": "800" }}>No</span>
        ),
    },
    {
        field: 'job_status', headerName: 'Status',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.4,
        headerAlign: 'center', align: 'center', minWidth: 100,
        renderCell: (params: GridRenderCellParams<string>) => (
            <JobStauts params={params} />
        ),
    },
    {
        field: 'created_at', headerName: 'Created At',
        headerClassName: 'super-app-theme--header', type: 'number', flex: 0.5,
        headerAlign: 'center', align: 'center', minWidth: 130,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.created_at).format('DD/MM/YYYY')}</label>
            </>
        )
    },
    {
        field: 'Action', headerName: 'Actions', type: 'string',
        headerClassName: 'super-app-theme--header', minWidth: 100,
        sortable: false, flex: 0.4, headerAlign: 'center', align: 'center',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <ButtonGroup size="small" aria-label="small button group">
                    <Link to={`/admin/jobs/detail/${params.row.id}/`} style={{ textDecoration: 'none' }}>
                        <VisibilityIcon sx={{ "color": "##0a58ca" }} />
                    </Link>
                </ButtonGroup>
            </>
        )
    },
]

function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{ mr: 2 }} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}


interface controllerInterface {
    pageNumber: number,
    pageLimit: number
}


interface employerJobListContentDataTableProps {
    employerJobListContent: employerJobListContentDataModified;
    jobsCount: number,
    setJobsCount: any,
    controller: controllerInterface,
    setController: any,
    searchTerm: string,
    setSearchTerm: any,
    loading: boolean,
    setLoading: any,
    isDesktop: boolean,
}

const JobListDataTable = (employerJobsDatatableProps: employerJobListContentDataTableProps) => {
    let {
        employerJobListContent,
        jobsCount,
        setJobsCount,
        controller,
        setController,
        searchTerm,
        setSearchTerm,
        loading,
        setLoading,
        isDesktop
    } = employerJobsDatatableProps;

    const handlePageChange = (newPage: number) => {
        setController({
            ...controller,
            pageNumber: newPage
        });
        localStorage.setItem('employerJobsPageNumber', String(newPage));
    };

    const handleChangeRowsPerPage = (newPageSize: number) => {
        setController({
            ...controller,
            pageLimit: newPageSize
        });
    };

    // To save search text in state so that on page reload text remains in search text as it is
    useEffect(() => {
        const savedSearchText = localStorage.getItem('employerJobSearchText');
        if (savedSearchText !== null) {
            setSearchTerm(savedSearchText);
        }
    }, []);

    const [rows, setRows] = React.useState<employerJobListContentDataModified>(
        employerJobListContent
    );

    const handleSearch = (searchValue: string) => {
        localStorage.setItem('employerJobSearchText', searchValue);
        // debouncedSearch(searchValue);
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = employerJobListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    };

    const debouncedSearch = useMemo(() => debounce((searchValue: string) => {
        setSearchTerm(searchValue);
        // setController({
        //     ...controller,
        //     pageNumber: 0
        // });
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = employerJobListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    }, 10), []); // Set the debounce delay to 500ms.

    useEffect(() => {
        setRows(employerJobListContent);
    }, [employerJobListContent]);

    return (
        <>
            {/* client side pagination */}
            {/* <div className='datagrid__layout' style={{ height: 750, width: '100%' }}>
                <DataGrid
                    className='datagrid--custom'
                    components={{  Toolbar: QuickSearchToolbar }}
                    rows={rows}
                    columns={columns}
                    // pageSize={10}
                    // rowsPerPageOptions={[10]}
                    density={'comfortable'}
                    autoPageSize={true}
                    componentsProps={{ 
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                    // sx={{
                    //     '.MuiDataGrid-columnSeparator': {
                    //       display: 'none',
                    //     },
                    //     '&.MuiDataGrid-root': {
                    //       border: 'none',
                    //     },
                    //   }}
                />
            </div> */}

            {/* server side pagination */}

            <div
                // className='datagrid__layout sticky-col'
                className={`datagrid__layout ${isDesktop ? "sticky-col" : ""}`}
                style={{
                    // height: 750,
                    width: '100%'
                }}
            >
                <DataGrid
                    className='datagrid--custom'
                    components={{ Toolbar: QuickSearchToolbar }}

                    sx={{
                        padding: "0px !important",
                        overflowx: 'scroll'
                    }}
                    autoHeight={true}

                    paginationMode='server'
                    rowCount={jobsCount}
                    rowsPerPageOptions={[controller.pageLimit]}
                    pagination
                    onPageChange={(newPage) => handlePageChange(newPage)}
                    onPageSizeChange={(newPageSize) => handleChangeRowsPerPage(newPageSize)}
                    rows={rows}
                    columns={columns}
                    page={controller.pageNumber}
                    pageSize={controller.pageLimit}
                    density={'comfortable'}
                    loading={loading}
                    // autoPageSize={true}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => handleSearch(event.target.value),
                            clearSearch: () => handleSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
};

export default JobListDataTable;

