import {
    SKILL_LIST_CONTENT_DATA, SKILL_STATUS_UPDATE
} from '../../constants';
import { skillListContentDataModifiedObj } from 'src/data/mappers/SkillList';
import { SkillListContentAction } from './types';


export const InsertSkillListContent = (data: Array<skillListContentDataModifiedObj>): SkillListContentAction => ({
    type: SKILL_LIST_CONTENT_DATA,
    data,
});


export const SkillStatusUpdate = (data:  number) => ({
    type: SKILL_STATUS_UPDATE,
    data,
});

